import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Menu } from "semantic-ui-react";

interface menuProps {
  name: string;
  path: string;
}

const MenuItem: FunctionComponent<menuProps> = (props: menuProps) => {
  return (
    <Menu.Item as={NavLink} to={props.path}>
      {props.name}
    </Menu.Item>
  );
};

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

export default MenuItem;
