import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Tab,
  List,
  Button,
  Rating,
  Form,
  Grid,
  Message,
} from "semantic-ui-react";
import { IReference, sortReferences } from "../../lib/library";
import {
  getReferences,
  deleteReference,
  updateReference,
  addReference,
} from "../../lib/axios";
import ConfirmationModal from "./ConfirmationModal";

interface IAdminReference extends IReference {
  IsModalOpen: boolean;
  IsUpdated: boolean;
  IsNew: boolean;
}

const ReferencesEdit: FunctionComponent<{}> = () => {
  const [content, setContent] = useState<IAdminReference[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const getData = async () => {
    const result = await getReferences();
    if (result instanceof Array) {
      let sortedResult = [...result].sort(sortReferences);
      sortedResult.forEach((element: IAdminReference) => {
        element.IsModalOpen = false;
      });
      setContent(sortedResult);
    } else {
      setError(result);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    content.forEach(async (item: IAdminReference) => {
      setLoading(true);
      if (item.IsNew) {
        await addReference(item);
      } else {
        if (item.IsUpdated) {
          await updateReference(item._id || "0000", item);
        }
      }
    });
    getData();
  };

  const handleOpenConfirmModal = (index: number): void => {
    const state = [...content];
    const stateItem = state[index];
    stateItem.IsModalOpen = true;
    setContent(state);
  };

  const handleCloseConfirmModal = (index: number): void => {
    const state = [...content];
    const stateItem = state[index];
    stateItem.IsModalOpen = false;
    setContent(state);
  };

  const handleUpdateState = (index: number, item: IAdminReference): void => {
    const state = [...content];
    const updatedStateItem = item;
    state[index] = updatedStateItem;
    setContent(state);
  };

  const handleAddNewRef = async () => {
    const newRef: IAdminReference = {
      message: "",
      name: "",
      priority: 10,
      surname: "",
      IsModalOpen: false,
      IsUpdated: false,
      IsNew: true,
    };
    const state = [...content];
    state.unshift(newRef);
    setContent(state);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Tab.Pane loading={loading}>
      <List selection relaxed divided>
        <List.Item>
          <List.Content>
            <Grid stackable columns="2" className="adminRefGrid">
              <Grid.Row columns="2">
                <Grid.Column width="4">
                  <Button
                    primary
                    icon="cloud upload"
                    labelPosition="left"
                    content="Uložit Změny"
                    onClick={handleSubmit}
                  />
                </Grid.Column>
                <Grid.Column width="6">
                  <Button
                    secondary
                    icon="user plus"
                    labelPosition="left"
                    content="Přidat Novou Referenci"
                    onClick={handleAddNewRef}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </List.Content>
        </List.Item>
        {content.map((item: IAdminReference, index: number) => (
          <List.Item key={item._id}>
            <List.Content>
              <Form className="editRefForm">
                <Form.Group widths="equal">
                  <Form.Input
                    control="input"
                    label="Jméno"
                    placeholder="Jméno"
                    value={item.name}
                    onChange={(event) => {
                      const target = event.target as HTMLInputElement;
                      const newItem = Object.assign({}, item, {
                        name: target.value,
                        IsUpdated: true,
                      });
                      handleUpdateState(index, newItem);
                    }}
                  />
                  <Form.Input
                    label="Příjmení"
                    placeholder="Příjmení"
                    value={item.surname}
                    onChange={(event) => {
                      const target = event.target as HTMLInputElement;
                      const newItem = Object.assign({}, item, {
                        surname: target.value,
                        IsUpdated: true,
                      });
                      handleUpdateState(index, newItem);
                    }}
                  />
                </Form.Group>
                <Form.TextArea
                  label="Text Reference"
                  placeholder="Zde napište text reference"
                  value={item.message}
                  onChange={(event) => {
                    const target = event.target as HTMLTextAreaElement;
                    const newItem = Object.assign({}, item, {
                      message: target.value,
                      IsUpdated: true,
                    });
                    handleUpdateState(index, newItem);
                  }}
                />
                <div>
                  <div>Priorita: {item.priority}</div>
                  <Form.Input
                    type="range"
                    min={0}
                    max={10}
                    value={item.priority}
                    onChange={(event) => {
                      const target = event.target as HTMLInputElement;
                      const newItem = Object.assign({}, item, {
                        priority: parseInt(target.value),
                        IsUpdated: true,
                      });
                      handleUpdateState(index, newItem);
                    }}
                  ></Form.Input>
                </div>
                <Rating disabled rating={item.priority} maxRating={10} />
                <div className="refDeleteButton">
                  <ConfirmationModal
                    isOpened={item.IsModalOpen}
                    header="Smazat Referenci"
                    message="Opravdu chcete smazat referenci? Operace je nevratná!"
                    databaseAction={deleteReference}
                    handleCloseModal={() => handleCloseConfirmModal(index)}
                    updateFunc={getData}
                    id={item._id}
                  >
                    <Button
                      negative
                      icon="trash alternate"
                      labelPosition="left"
                      content="Smazat Referenci"
                      onClick={() => handleOpenConfirmModal(index)}
                    />
                  </ConfirmationModal>
                </div>
              </Form>
            </List.Content>
          </List.Item>
        ))}
        {error !== "" && (
          <List.Item>
            <Message error header="Chyba Komunikace" content={error} />
          </List.Item>
        )}
      </List>
    </Tab.Pane>
  );
};

export default ReferencesEdit;
