import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import ResponsiveContainer from "../components/ResponsiveComponent";
import Footer from "../components/Footer";
import { Link, Element } from "react-scroll";
import CounterHomeBox from "../components/CounterHomeBox";
import {
  Grid,
  Image,
  Container,
  Responsive,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import Carousel from "../components/Carousel";
import { getWidth, IStatistics } from "../lib/library";
import Squares from "../components/Squares";
import ContactMe from "../components/ContactLink";
import EstateCatalogLink from "../components/EstatesCatalogLink";

interface HomeProps {
  personalData: IStatistics;
}

const Home: FunctionComponent<HomeProps> = (props: HomeProps) => {
  return (
    <ResponsiveContainer isHomepage={true} personalData={props.personalData}>
      <div className="content">
        <Container>
          <Grid columns="2" verticalAlign="middle">
            <Grid.Row>
              <Grid.Column widescreen="9" computer="9" tablet="16" mobile="16">
                <Container className="homeIntro" textAlign="center">
                  <div className="logo">
                    <Image
                      src={`${process.env.PUBLIC_URL}/logo.svg`}
                      verticalAlign="bottom"
                      size="large"
                    />
                  </div>
                  <Header as="h1" textAlign="left" icon>
                    Jsem realitní makléř, na kterého se můžete spolehnout
                    <Header.Subheader>
                      Potřebujete koupit či prodat nemovitost? Rád Vám pomohu.
                      Neváhejte se na mě obrátit.
                    </Header.Subheader>
                  </Header>
                  <Responsive
                    getWidth={getWidth}
                    maxWidth={Responsive.onlyTablet.maxWidth}
                  >
                    <div className="logoMobile">
                      <Image
                        className="broker"
                        src={`${process.env.PUBLIC_URL}/title.png`}
                        verticalAlign="bottom"
                      />
                    </div>
                  </Responsive>
                  <Segment vertical color="red" className="homeBox">
                    <CounterHomeBox personalData={props.personalData} />
                  </Segment>

                  <Link to="mojePrace" spy smooth offset={-10}>
                    <Icon
                      className="arrowDown"
                      link
                      name="angle double down"
                      size="huge"
                      color="red"
                    />
                  </Link>
                </Container>
              </Grid.Column>
              <Grid.Column widescreen="7" computer="7" floated="right">
                <Responsive
                  getWidth={getWidth}
                  minWidth={Responsive.onlyComputer.minWidth}
                >
                  <div className="logo">
                    <Image
                      className="broker"
                      src={`${process.env.PUBLIC_URL}/title.png`}
                      verticalAlign="bottom"
                    />
                  </div>
                </Responsive>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Element name="mojePrace">
          <Carousel />
        </Element>
        <Container className="introServices" textAlign="center">
          <Header as="h1" color="red" className="introServicesHeader">
            Podívejte se na nabídku mých služeb
          </Header>
          <Squares />
        </Container>
        <EstateCatalogLink />
        <ContactMe
          personalData={props.personalData}
          label="Kontaktujte mě"
          areSocialNetworks
          className="contactAbout"
        />
      </div>
      <Footer personalData={props.personalData} />
    </ResponsiveContainer>
  );
};

Home.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default Home;
