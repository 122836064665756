import { Responsive, SemanticICONS } from "semantic-ui-react";

export interface IReference {
  _id?: string;
  priority: number;
  name: string;
  surname: string;
  message: string;
  createdDate?: Date;
}

export interface ILoading {
  isLoading: boolean;
}

export enum EstateType {
  Sell = "Prodej",
  Hire = "Pronájem",
  Sold = "Prodáno",
}

export interface ILoginData {
  username: string;
  password: string;
}

export interface IEstatesList {
  _id: string;
  date: Date;
  name: string;
  description: string;
  thumbnail: string;
  price: string;
  area: string;
  type: EstateType;
}

export interface IEstates {
  _id?: string;
  date: Date;
  name: string;
  description: string;
  highlights: IEstatesHighlight[];
  price: string;
  area: string;
  type: EstateType;
  hasVideo: boolean;
  videoLink?: string;
  images: IEstatesImages[];
}

export interface IEstatesHighlight {
  _id?: string;
  fieldName: string;
  fieldValue: string;
}

export interface IEstatesImages {
  _id?: string;
  priority: number;
  name: string;
  original?: string;
  thumbnail?: string;
}

export interface IMessageData {
  _id?: string;
  name: string;
  surname: string;
  phoneNumber: string;
  email: string;
  message: string;
  createdDate?: Date;
}

export interface IPersonalData {
  services: IServices[];
}
export interface IServices {
  name: string;
  icon: SemanticICONS;
  buttonText: string;
  label: string;
  class: string;
  subheader: string;
  description: string;
  points: string[];
}

export function validateEmail(input: string): boolean {
  const emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test(input);
}

export const personalDetails: IPersonalData = {
  services: [
    {
      name: "Prodej Nemovitosti",
      icon: "home",
      label: "Chci prodat nemovitost",
      buttonText: "Chci prodat nemovitost...",
      class: "sell",
      subheader:
        "Zajistím rychlý a hlavně bezstarostný prodej Vaší nemovitosti. Postarám se o všechny náležitosti a formality.",
      description: "",
      points: [],
    },
    {
      name: "Pronájem Nemovitosti",
      icon: "key",
      label: "Chci pronajmout nemovitost",
      buttonText: "Chci pronajmout nemovitost...",
      class: "rental",
      subheader:
        "Zajistím bezstarostný pronájem Vaší nemovitosti. Pomohu Vám vytvořit atraktivní nabídku a vybrat vhodného nájemce.",
      description: "",
      points: [],
    },
    {
      name: "Dědické Řízení",
      icon: "briefcase",
      label: "Mám zájem o vypracování odhadu",
      buttonText: "Mám zájem o vypracování odhadu ceny nemovitosti...",
      class: "price",
      subheader:
        "Potřebujete zjistit hodnotu vaší nemovitosti? Rád Vám s tím pomohu.",
      description:
        "Pokud je součástí dědického řízení nemovitost, notář po Vás bude požadovat předložení odhadu nemovitosti. Dnes již není nutné, aby tržní cenu stanovil soudní znalec, postačí Vám odhad vypracovaný realitním specialistou. Proto se neváhejte na mne obrátit, velmi rád Vám s odhadem pomohu.",
      points: [],
    },
  ],
};

export function getWidth(): number {
  const isSSR = typeof window === "undefined";
  const responsiveWidth = () => {
    if (
      typeof Responsive.onlyTablet.minWidth === "number" &&
      typeof Responsive.onlyTablet.minWidth === "undefined"
    ) {
      return Responsive.onlyTablet.minWidth;
    } else {
      return window.innerWidth;
    }
  };
  return isSSR ? responsiveWidth() : window.innerWidth;
}

export const routes = [
  {
    path: "/kdo-jsem",
    name: "O mně",
  },
  {
    path: "/reference",
    name: "Reference",
  },
  {
    path: "/nemovitosti",
    name: "Nemovitosti",
  },
  {
    path: "/sluzby",
    name: "Služby",
  },
  {
    path: "/kontakt",
    name: "Kontakt",
  },
];

export interface IStatistics {
  _id?: string;
  experience: number;
  realEstates: number;
  clients: number;
  pageHeader: string;
  phone: string;
  facebook: string;
  instagram: string;
  linkedIn: string;
  place: string;
  email: string;
  ico: string;
}

export const statistics: IStatistics = {
  experience: 10,
  realEstates: 100,
  clients: 199,
  pageHeader: "",
  phone: "",
  facebook: "",
  instagram: "",
  linkedIn: "",
  place: "",
  email: "",
  ico: "",
};

export function sortReferences(a: IReference, b: IReference): number {
  const priorityA = a.priority;
  const priorityB = b.priority;

  const dateA = a.createdDate;
  const dateB = b.createdDate;

  let comparison = 0;
  if (priorityA < priorityB) {
    comparison = 1;
  } else if (priorityA > priorityB) {
    comparison = -1;
  }

  if (dateA && dateB) {
    if (priorityA === priorityB && dateA > dateB) {
      comparison = -1;
    }
  }

  return comparison;
}

export function sortEstates(a: IEstatesList, b: IEstatesList): number {
  const priorityA = a.date;
  const priorityB = b.date;

  let comparison = 0;
  if (priorityA < priorityB) {
    comparison = 1;
  } else if (priorityA > priorityB) {
    comparison = -1;
  }

  return comparison;
}

export function sortMessages(a: IMessageData, b: IMessageData): number {
  const priorityA = a.createdDate;
  const priorityB = b.createdDate;

  let comparison = 0;
  if (priorityA && priorityB !== undefined) {
    if (priorityA < priorityB) {
      comparison = 1;
    } else if (priorityA > priorityB) {
      comparison = -1;
    }
  }
  return comparison;
}

export function dateFormatter(input: Date): string {
  let date = new Date(input);
  var formatter = new Intl.DateTimeFormat("cs", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return formatter.format(date);
}

export function dateFormatterWithTime(input: Date): string {
  let date = new Date(input);
  var formatter = new Intl.DateTimeFormat("cs", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return formatter.format(date);
}

export const emptyEstateObject: IEstates = {
  date: new Date(),
  description: "<p><br></p>",
  name: "",
  price: "",
  area: "",
  type: EstateType.Sell,
  images: [],
  hasVideo: false,
  highlights: [{ fieldName: "", fieldValue: "" }],
};

export function createMarkup(html: string): { __html: string } {
  return { __html: html };
}
