import React, { useEffect, Fragment, FunctionComponent } from "react";
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import Home from "./pages/Home";
import AboutMe from "./pages/AboutMe";
import References from "./pages/References";
import Error from "./pages/Error";
import Estates from "./pages/Estates";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import EstateDetail from "./pages/EstateDetail";
import Login from "./pages/Login";
import AdminPage from "./pages/AdminPage";
import { Helmet } from "react-helmet";
import useStatistics from "./hooks/useStatistics";

const ScrollToTop: React.FunctionComponent<RouteComponentProps> = ({
  history,
  children,
}) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history, children]);

  return <Fragment>{children}</Fragment>;
};

const Wraper = withRouter(ScrollToTop);

const App: FunctionComponent<{}> = () => {
  const { data } = useStatistics();

  return (
    <main>
      <Helmet>
        <title>Realitní Makléř Ing. Jan Jírek</title>
        <meta
          name="description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta itemProp="name" content="Realitní Makléř Ing. Jan Jírek" />
        <meta
          itemProp="image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta property="og:url" content="https://www.janjirek.cz" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Realitní Makléř Ing. Jan Jírek" />
        <meta
          property="og:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          property="og:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Realitní Makléř Ing. Jan Jírek" />
        <meta
          name="twitter:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          name="twitter:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
      </Helmet>
      <Wraper>
        <Switch>
          {data && (
            <Route
              exact
              path="/"
              render={(props) => <Home {...props} personalData={data[0]} />}
            />
          )}
          {data && (
            <Route
              path="/kdo-jsem"
              render={(props) => <AboutMe {...props} personalData={data[0]} />}
            />
          )}
          {data && (
            <Route
              path="/reference"
              render={(props) => (
                <References {...props} personalData={data[0]} />
              )}
            />
          )}
          {data && (
            <Route
              exact
              path="/nemovitosti"
              render={(props) => <Estates {...props} personalData={data[0]} />}
            />
          )}
          {data && (
            <Route
              exact
              path="/nemovitosti/:id"
              render={(props) => (
                <EstateDetail {...props} personalData={data[0]} />
              )}
            />
          )}
          {data && (
            <Route
              path="/sluzby"
              render={(props) => <Services {...props} personalData={data[0]} />}
            />
          )}
          {data && (
            <Route
              path="/kontakt"
              render={(props) => <Contact {...props} personalData={data[0]} />}
            />
          )}
          {data && (
            <Route
              exact
              path="/login"
              render={(props) => <Login {...props} personalData={data[0]} />}
            />
          )}
          {data && (
            <Route
              exact
              path="/7397efa4468a56b65ccde3218e6f798c"
              render={(props) => (
                <AdminPage {...props} personalData={data[0]} />
              )}
            />
          )}
          {data && (
            <Route
              render={(props) => <Error {...props} personalData={data[0]} />}
            />
          )}
        </Switch>
      </Wraper>
    </main>
  );
};

export default App;
