import React, { FunctionComponent, useState, FormEvent } from "react";
import { Button, Form, Message } from "semantic-ui-react";
import { IStatistics, validateEmail } from "../lib/library";
import { postMessage } from "../lib/axios";
import { IMessageData } from "../lib/library";
import TermsModal from "./TermsconditionsModal";

interface IFormData {
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  message: string;
  formSent: boolean;
  emailValid: boolean;
  agreed: boolean;
  formSuccess: boolean | undefined;
}

interface ContactFormProps {
  predefinedText?: string;
  personalData: IStatistics;
}

const ContactForm: FunctionComponent<ContactFormProps> = (
  props: ContactFormProps
) => {
  const [formData, setFormdata] = useState<IFormData>({
    name: "",
    email: "",
    surname: "",
    phoneNumber: "",
    message: props.predefinedText || "",
    formSent: false,
    emailValid: false,
    agreed: false,
    formSuccess: undefined,
  });

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const checker = (): boolean => {
    if (
      formData.name === "" ||
      formData.surname === "" ||
      formData.email === "" ||
      formData.phoneNumber === "" ||
      formData.message === "" ||
      formData.agreed === false
    ) {
      return true;
    } else return false;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmail(formData.email)) {
      const makeCall = async () => {
        const messageData: IMessageData = {
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          name: formData.name,
          surname: formData.surname,
          message: formData.message,
        };
        const result = await postMessage(messageData);
        if (result === 200) {
          setFormdata(
            Object.assign({}, formData, {
              formSuccess: true,
              emailValid: false,
            })
          );
        } else {
          setFormdata(
            Object.assign({}, formData, {
              formSuccess: false,
              emailValid: false,
            })
          );
        }
      };
      makeCall();
    } else {
      setFormdata(Object.assign({}, formData, { emailValid: true }));
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      success={formData.formSuccess}
      error={formData.formSuccess !== undefined && !formData.formSuccess}
      className="contactForm"
    >
      <Form.Group widths="equal">
        <Form.Input
          control="input"
          label="Jméno"
          placeholder="Jméno"
          value={formData.name}
          onChange={(event) => {
            const target = event.target as HTMLInputElement;
            setFormdata(Object.assign({}, formData, { name: target.value }));
          }}
        />
        <Form.Input
          label="Příjmení"
          placeholder="Příjmení"
          value={formData.surname}
          onChange={(event) => {
            const target = event.target as HTMLInputElement;
            setFormdata(Object.assign({}, formData, { surname: target.value }));
          }}
        />
      </Form.Group>
      <Form.Input
        label="Email"
        placeholder="Email"
        value={formData.email}
        onChange={(event) => {
          const target = event.target as HTMLInputElement;
          setFormdata(Object.assign({}, formData, { email: target.value }));
        }}
        error={
          formData.emailValid && {
            content: "Zadejte prosím platnou emailovou adresu",
            pointing: "below",
          }
        }
      />
      <Form.Input
        label="Telefon"
        placeholder="Telefon"
        value={formData.phoneNumber}
        onChange={(event) => {
          const target = event.target as HTMLInputElement;
          setFormdata(
            Object.assign({}, formData, { phoneNumber: target.value })
          );
        }}
      />
      <Form.TextArea
        label="Vaše zpráva"
        placeholder="Zde napiště Vaši zprávu..."
        value={formData.message}
        onChange={(event) => {
          const target = event.target as HTMLTextAreaElement;
          setFormdata(Object.assign({}, formData, { message: target.value }));
        }}
      />
      <Form.Field>
        <Form.Checkbox
          checked={formData.agreed}
          inline
          className="termsCheckbox"
          onChange={() => {
            setFormdata(
              Object.assign({}, formData, { agreed: !formData.agreed })
            );
          }}
        />
        <TermsModal
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          personalData={props.personalData}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              setIsOpened(true);
            }}
            className="textButton"
            fluid
          >
            Souhlasím se zpracováním osobních údajů pouze za účelem zodpovězení
            Vašeho dotazu.
          </Button>
        </TermsModal>
      </Form.Field>
      <Form.Button color="red" type="submit" disabled={checker()}>
        Odeslat
      </Form.Button>
      <Message
        success
        content="Děkuji za zanechání Vaší zprávy, odpovím Vám v nejbližší možné době."
        header="Vaše zpráva byla odeslána."
      />
      <Message
        error
        content="Omlouváme se, ale došlo k problému při odesílání Vaší zprávy, zkuste to později nebo mě kontaktujte prostřednictvím jiného kanálu."
        header="Problém při odesílání."
      />
    </Form>
  );
};

export default ContactForm;
