import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { IStatistics } from "../lib/library";

interface SocialNetworkProps {
  inverted: boolean;
  personalData: IStatistics;
}

const SocialNetwork: FunctionComponent<SocialNetworkProps> = (
  props: SocialNetworkProps
) => {
  const content = (): React.ReactElement<SocialNetworkProps> => {
    if (props.inverted) {
      return (
        <div className="socialNetwork">
          {props.personalData.facebook !== "" && (
            <a
              href={props.personalData.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon link name="facebook square" inverted size="big" />
            </a>
          )}
          {props.personalData.linkedIn !== "" && (
            <a
              href={props.personalData.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon link name="linkedin square" inverted size="big" />
            </a>
          )}
          {props.personalData.instagram !== "" && (
            <a
              href={props.personalData.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon link name="instagram" inverted size="big" />
            </a>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {props.personalData.facebook !== "" && (
            <a
              href={props.personalData.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon link name="facebook square" color="red" size="big" />
            </a>
          )}
          {props.personalData.linkedIn !== "" && (
            <a
              href={props.personalData.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon link name="linkedin square" color="red" size="big" />
            </a>
          )}
          {props.personalData.instagram !== "" && (
            <a
              href={props.personalData.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon link name="instagram" color="red" size="big" />
            </a>
          )}
        </div>
      );
    }
  };

  return content();
};

SocialNetwork.propTypes = {
  inverted: PropTypes.bool.isRequired,
  personalData: PropTypes.any.isRequired,
};

export default SocialNetwork;
