import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import ResponsiveContainer from "../components/ResponsiveComponent";
import Footer from "../components/Footer";
import {
  Header,
  Container,
  Segment,
  Grid,
  Divider,
  Image,
} from "semantic-ui-react";
import SocialNetwork from "../components/SocialNetwork";
import { IStatistics } from "../lib/library";
import ContactForm from "../components/ContactForm";
import { Helmet } from "react-helmet";

interface ContactProps {
  location: any;
  personalData: IStatistics;
}

const Contact: FunctionComponent<ContactProps> = (props: ContactProps) => {
  const predText: string = props.location.contactProps
    ? props.location.contactProps.predefinedText
    : "";
  return (
    <div>
      <Helmet>
        <title>Ing. Jan Jírek - Kontakty</title>
        <meta itemProp="name" content="Ing. Jan Jírek - Kontakty" />
        <meta
          itemProp="description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          itemProp="image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta property="og:url" content="https://www.janjirek.cz" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ing. Jan Jírek - Kontakty" />
        <meta
          property="og:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          property="og:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ing. Jan Jírek - Kontakty" />
        <meta
          name="twitter:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          name="twitter:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
      </Helmet>
      <ResponsiveContainer isHomepage={false} personalData={props.personalData}>
        <div className="content">
          <Container>
            <Segment vertical className="about">
              <Grid stackable verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column
                    width={8}
                    textAlign="center"
                    verticalAlign="middle"
                    className="contact-col"
                  >
                    <Image
                      centered
                      src={`${process.env.PUBLIC_URL}/ContactImage.png`}
                      verticalAlign="bottom"
                    />
                    <Segment
                      color="red"
                      textAlign="center"
                      className="contactList"
                    >
                      <Header as="h2">
                        {props.personalData.pageHeader}
                        <Header.Subheader>
                          Telefon:{" "}
                          <a
                            href={`tel:${props.personalData.phone}`}
                            className="customphonelink"
                          >
                            {props.personalData.phone}
                          </a>
                        </Header.Subheader>
                        <Header.Subheader>
                          Email:{" "}
                          <a
                            href={`mailto:${props.personalData.email}`}
                            className="customlink"
                          >
                            {props.personalData.email}
                          </a>
                        </Header.Subheader>
                        {props.personalData.ico !== "" && (
                          <Header.Subheader>
                            IČO: {props.personalData.ico}
                          </Header.Subheader>
                        )}
                        <Header.Subheader>
                          Oblasti působení: {props.personalData.place}
                        </Header.Subheader>
                      </Header>
                      <SocialNetwork
                        inverted={false}
                        personalData={props.personalData}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="center">
                    <Header as="h3">
                      Máte zájem o mé služby? Neváhejte mě kontaktovat!
                    </Header>
                    <Divider />
                    <Segment color="red">
                      <ContactForm
                        predefinedText={predText}
                        personalData={props.personalData}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        </div>
        <Footer personalData={props.personalData} />
      </ResponsiveContainer>
    </div>
  );
};

Contact.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default Contact;
