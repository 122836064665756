import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Container, Button, Icon, SemanticCOLORS } from "semantic-ui-react";
import { IStatistics } from "../lib/library";

interface ContactLinkProps {
  className?: string | undefined;
  color?: SemanticCOLORS;
  label: string;
  predText?: string;
  areSocialNetworks: boolean;
  personalData?: IStatistics;
}

const ContactLink: FunctionComponent<ContactLinkProps> = (
  props: ContactLinkProps
) => {
  return (
    <Container className={props.className} textAlign="center">
      <Button
        size="big"
        as={NavLink}
        to={{
          pathname: "/Kontakt",
          contactProps: { predefinedText: props.predText || "" },
        }}
        color={props.color ? props.color : "black"}
      >
        <Icon name="mail" />
        {props.label}
      </Button>
      {props.areSocialNetworks && props.personalData && (
        <div className="socialButtons">
          {props.personalData.facebook !== "" && (
            <Button
              className="social"
              as="a"
              href={props.personalData.facebook}
              target="_blank"
              color="facebook"
            >
              <Icon name="facebook" />
              Facebook
            </Button>
          )}

          {props.personalData.linkedIn !== "" && (
            <Button
              className="social"
              as="a"
              href={props.personalData.linkedIn}
              target="_blank"
              color="linkedin"
            >
              <Icon name="linkedin" />
              LinkedIn
            </Button>
          )}

          {props.personalData.instagram !== "" && (
            <Button
              className="social"
              as="a"
              href={props.personalData.instagram}
              target="_blank"
              color="instagram"
            >
              <Icon name="instagram" />
              Instagram
            </Button>
          )}

          <Button
            className="social"
            as="a"
            href={`mailto:${props.personalData.email}`}
            target="_blank"
            color="google plus"
          >
            <Icon name="mail" />
            Email
          </Button>
        </div>
      )}
    </Container>
  );
};

ContactLink.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  predText: PropTypes.string,
  personalData: PropTypes.any.isRequired,
};

export default ContactLink;
