import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  Button,
  Header,
  Icon,
  Divider,
  Container,
  Segment,
} from "semantic-ui-react";
import ResponsiveContainer from "../components/ResponsiveComponent";
import Footer from "../components/Footer";
import { IStatistics } from "../lib/library";

interface ErrorProps {
  personalData: IStatistics;
}

const Error: FunctionComponent<ErrorProps> = (props: ErrorProps) => {
  return (
    <ResponsiveContainer isHomepage={false} personalData={props.personalData}>
      <div className="content">
        <Segment vertical className="wrapper">
          <Container text>
            <Header as="h2" size="small">
              <Icon name="bug" />
              <Header.Content>
                Požadovaná stránka nebyla nalezena. Použijte tlačítko níže pro
                návrat na úvodní stránku.
              </Header.Content>
            </Header>
            <Divider />
            <div>
              <Button as={NavLink} to="/">
                Úvodní Stránka
              </Button>
            </div>
          </Container>
        </Segment>
      </div>
      <Footer personalData={props.personalData} />
    </ResponsiveContainer>
  );
};

Error.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default Error;
