import { getStatistics } from "../lib/axios";
import { useQuery } from "react-query";

interface QueryResultSet {
  data: any;
  isLoading: boolean;
  isFetching: boolean;
}

const useStatistics = (): QueryResultSet => {
  const { data, isLoading, isFetching } = useQuery(
    "statistics",
    getStatistics,
    { staleTime: 1800000 }
  );
  return { data, isLoading, isFetching };
};

export default useStatistics;
