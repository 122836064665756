import axios from "axios";
import {
  IMessageData,
  IEstates,
  IEstatesList,
  ILoginData,
  IReference,
  IStatistics,
  sortEstates,
  sortReferences,
} from "./library";

const instance = axios.create();

export const getReferences = (): Promise<any> => {
  return instance
    .get(`/api/reference`)
    .then((resp) => {
      const responseData: IReference[] = resp.data;
      const sortedData = [...responseData].sort(sortReferences);
      return sortedData;
    })
    .catch((error) => error.response);
};

export const postMessage = (data: IMessageData): Promise<any> => {
  return instance
    .post(`/api/message`, { data })
    .then((resp) => resp.status)
    .catch((error) => error.response);
};

export const getEstateList = (): Promise<any> => {
  return instance
    .get(`/api/estate`)
    .then((resp) => {
      const responseData: IEstatesList[] = resp.data;
      const sortedData = [...responseData].sort(sortEstates);
      return sortedData;
    })
    .catch((error) => error.response);
};

export const getEstateDetail = (queryKey: string, id: string): Promise<any> => {
  return instance
    .get(`/api/estate/${id}`)
    .then((resp) => {
      const responseData: IEstates = resp.data;
      return responseData;
    })
    .catch((error) => error.response);
};

export const checkToken = (): Promise<any> => {
  return instance
    .get(`/api/checkToken`)
    .then((resp) => resp.status)
    .catch((error) => error.response);
};

export const getStatistics = (): Promise<any> => {
  return instance
    .get(`/api/statistics`)
    .then((resp) => resp.data)
    .catch((error) => error.response);
};

const instanceProtected = axios.create({ withCredentials: true });

export const postLogin = (data: ILoginData): Promise<any> => {
  return instanceProtected
    .post(`/api/auth`, { data })
    .then((resp) => resp.status)
    .catch((error) => error.response);
};

export const getMessages = (): Promise<any> => {
  return instanceProtected
    .get(`/api/message`)
    .then((resp) => resp.data)
    .catch((error) => error.response);
};

export const deleteMessage = (id: string): Promise<any> => {
  return instanceProtected
    .delete(`/api/message/${id}`)
    .then((resp) => resp.data)
    .catch((error) => error.response);
};

export const deleteAllMessages = (): Promise<any> => {
  return instanceProtected
    .delete(`/api/message`)
    .then((resp) => resp.data)
    .catch((error) => error.response);
};

export const deleteReference = (id: string): Promise<any> => {
  return instanceProtected
    .delete(`/api/reference/${id}`)
    .then((resp) => resp.data)
    .catch((error) => error.response);
};

export const updateReference = (id: string, data: IReference): Promise<any> => {
  return instanceProtected
    .put(`/api/reference/${id}`, { data })
    .then((resp) => resp.data)
    .catch((error) => error.response);
};

export const addReference = (data: IReference): Promise<any> => {
  return instanceProtected
    .post(`/api/reference`, { data })
    .then((resp) => resp.status)
    .catch((error) => error.response);
};

export const addEstate = (data: IEstates): Promise<any> => {
  return instanceProtected
    .post(`/api/estate`, { data })
    .then((resp) => resp.status)
    .catch((error) => error.response);
};

export const deleteEstate = (id: string): Promise<any> => {
  return instanceProtected
    .delete(`/api/estate/${id}`)
    .then((resp) => resp.data)
    .catch((error) => error.response);
};

export const updateEstate = (id: string, data: FormData): Promise<any> => {
  return instanceProtected
    .put(`/api/estate/${id}`, data)
    .then((resp) => resp.data)
    .catch((error) => error.response);
};

export const addStatistics = (data: IStatistics): Promise<any> => {
  return instance
    .post(`/api/statistics`, { data })
    .then((resp) => resp.status)
    .catch((error) => error.response);
};

export const updateStatistics = (
  id: string,
  data: IStatistics
): Promise<any> => {
  return instanceProtected
    .put(`/api/statistics/${id}`, { data })
    .then((resp) => resp.data)
    .catch((error) => error.response);
};
