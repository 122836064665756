import React, { FunctionComponent, useState } from "react";
import PropTypes from "prop-types";
import {
  Segment,
  Container,
  Grid,
  Header,
  List,
  Image,
  Icon,
  Button,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { routes, IStatistics } from "../lib/library";
import SocialNetwork from "./SocialNetwork";
import TermsModal from "./TermsconditionsModal";

interface FooterProps {
  personalData: IStatistics;
}

const Footer: FunctionComponent<FooterProps> = (props: FooterProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  return (
    <Segment inverted vertical className="footer">
      <Container>
        <Grid doubling divided inverted stackable>
          <Grid.Row>
            <Grid.Column widescreen="3" computer="3" tablet="3">
              <Header inverted as="h4" content="Navigace" />
              <List link inverted>
                <List.Item as={NavLink} exact to="/">
                  Úvodní stránka
                </List.Item>
                {routes.map((item) => (
                  <List.Item key={item.path} as={NavLink} to={item.path}>
                    {item.name}
                  </List.Item>
                ))}
                <List.Item>
                  <TermsModal
                    isOpened={isOpened}
                    setIsOpened={setIsOpened}
                    personalData={props.personalData}
                  >
                    <Button
                      className="textFooterButton"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsOpened(true);
                      }}
                    >
                      Ochrana osobních údajů
                    </Button>
                  </TermsModal>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column widescreen="4" computer="4" tablet="6">
              <Header as="h4" inverted>
                Kotaktujte mě
              </Header>
              <List link inverted>
                <List.Item>
                  <SocialNetwork inverted personalData={props.personalData} />
                </List.Item>
                <List.Item>
                  <Header as="h4" inverted className="footer">
                    <p>
                      <Icon name="phone square" inverted size="big" />
                      <a
                        href={`tel:${props.personalData.phone}`}
                        className="customphonemenulink"
                        id="customFooterLink"
                      >
                        {props.personalData.phone}
                      </a>
                    </p>
                  </Header>
                </List.Item>
                <List.Item>
                  <Header as="h4" inverted className="footer">
                    <p>
                      <Icon name="mail" inverted size="big" />
                      <a
                        href={`mailto:${props.personalData.email}`}
                        className="customphonemenulink"
                        id="customFooterLink"
                      >
                        {" "}
                        {props.personalData.email}
                      </a>
                    </p>
                  </Header>
                  {props.personalData.ico !== "" && (
                    <Header as="h4" inverted className="footer">
                      <p>IČO: {props.personalData.ico}</p>
                    </Header>
                  )}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column widescreen="9" computer="8" tablet="7">
              <div>
                <p>
                  © {new Date().getFullYear()} Realitní Makléř{" "}
                  {props.personalData.pageHeader}
                </p>
                <a href="https://aws.amazon.com/what-is-cloud-computing">
                  <img
                    width="150em"
                    src="https://d0.awsstatic.com/logos/powered-by-aws-white.png"
                    alt="Powered by AWS Cloud Computing"
                  />
                </a>
                <Image
                  className="footerMark"
                  src={`${process.env.PUBLIC_URL}/LinogeInteractiveBlackBkg.png`}
                  verticalAlign="bottom"
                  size="tiny"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

Footer.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default Footer;
