import { getEstateList } from "../lib/axios";
import { useQuery } from "react-query";

interface QueryResultSet {
  data: any;
  isLoading: boolean;
  isFetching: boolean;
}

const useEstates = (): QueryResultSet => {
  const { data, isLoading, isFetching } = useQuery("estates", getEstateList, {
    staleTime: 1000000,
  });
  return { data, isLoading, isFetching };
};

export default useEstates;
