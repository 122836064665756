import React, { FunctionComponent, useState } from "react";
import {
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  CompositeDecorator,
  ContentBlock,
  ContentState,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

interface IEditorArea {
  description: string;
  handleUpdateDescription(htmlString: string): void;
}

const EditorArea: FunctionComponent<IEditorArea> = (props: IEditorArea) => {
  const decorator = createLinkDecorator();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(stateFromHTML(props.description), decorator)
  );

  const handleKeyCommand = (command: any, editorState: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);

      return "handled";
    }

    return "not-handled";
  };

  const onTab = (e: any) => {
    const maxDepth = 4;
    setEditorState(RichUtils.onTab(e, editorState, maxDepth));
  };

  const toggleBlockType = (blockType: any) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle: any) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const toggleLink = (entityKey: string) => {
    setEditorState(
      RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey)
    );
  };

  let className = "RichEditor-editor";
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (
      contentState
        .getBlockMap()
        .first()
        .getType() !== "unstyled"
    ) {
      className += " RichEditor-hidePlaceholder";
    }
  }

  return (
    <div className="RichEditor-root">
      <BlockStyleControls
        editorState={editorState}
        onToggle={toggleBlockType}
      />
      <InlineStyleControls
        editorState={editorState}
        onToggle={toggleInlineStyle}
      />
      <LinkButton editorState={editorState} setEditorState={setEditorState} />
      <div className={className} onClick={window.focus}>
        <Editor
          editorState={editorState}
          onChange={(editorState: any) => {
            setEditorState(editorState);
            props.handleUpdateDescription(
              stateToHTML(editorState.getCurrentContent(), options)
            );
          }}
          handleKeyCommand={handleKeyCommand}
          onTab={onTab}
          customStyleMap={styleMap}
          blockStyleFn={getBlockStyle}
        />
      </div>
    </div>
  );
};

export default EditorArea;

const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

const options = {
  inlineStyles: {
    BOLD: { element: "b" },
    CODE: {
      style: styleMap.CODE,
    },
  },
  blockStyleFn: (block: any) => {
    if (block.getType() === "blockquote") {
      return {
        style: {
          borderLeft: "5px solid #eee",
          color: "#666",
          fontFamily: '"Hoefler Text", "Georgia", serif',
          fontStyle: "italic",
          margin: "16px 0",
          padding: "10px 20px",
        },
      };
    }
    if (block.getType().indexOf("header") !== -1) {
      return {
        attributes: {
          class: "ui header",
        },
      };
    }
    if (block.getType() === "unordered-list-item") {
      return {
        attributes: {
          class: "item",
        },
      };
    }
    if (block.getType() === "ordered-list-item") {
      return {
        attributes: {
          class: "item",
        },
      };
    }
  },
  entityStyleFn: (entity: any) => {
    const entityType = entity.get("type").toLowerCase();
    if (entityType === "link") {
      const data = entity.getData();
      return {
        element: "a",
        attributes: {
          href: data.url,
          target: "_blank",
        },
        style: linkStyle,
      };
    }
  },
};

function getBlockStyle(block: any) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return "null";
  }
}

interface IStyleButton {
  key: string;
  active: boolean;
  onToggle: any;
  label: string;
  style: any;
}

const StyleButton: FunctionComponent<IStyleButton> = (props: IStyleButton) => {
  const onToggle = (e: any) => {
    e.preventDefault();
    props.onToggle(props.style);
  };

  let className = "RichEditor-styleButton";
  if (props.active) {
    className = "RichEditor-activeButton";
  }

  return (
    <span className={className} onMouseDown={onToggle}>
      {props.label}
    </span>
  );
};

const BLOCK_TYPES = [
  { label: "H1 Nadpis", style: "header-one" },
  { label: "H2 Nadpis", style: "header-two" },
  { label: "H3 Nadpis", style: "header-three" },
  { label: "H4 Nadpis", style: "header-four" },
  { label: "H5 Nadpis", style: "header-five" },
  { label: "H6 Nadpis", style: "header-six" },
  { label: "Citace", style: "blockquote" },
  { label: "Odrážky", style: "unordered-list-item" },
  { label: "Číslování", style: "ordered-list-item" },
];

var INLINE_STYLES = [
  { label: "Tučně", style: "BOLD" },
  { label: "Italika", style: "ITALIC" },
  { label: "Podtržení", style: "UNDERLINE" },
  { label: "Zvýraznění", style: "CODE" },
];

interface IBlockStyleControls {
  editorState: any;
  onToggle: any;
}

const BlockStyleControls: FunctionComponent<IBlockStyleControls> = (
  props: IBlockStyleControls
) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div>
      <b>Stylování Odstavců</b>
      <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type) => (
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            style={type.style}
            onToggle={props.onToggle}
          />
        ))}
      </div>
    </div>
  );
};

interface IInlineStyleControls {
  editorState: any;
  onToggle: any;
}

const InlineStyleControls: FunctionComponent<IInlineStyleControls> = (
  props: IInlineStyleControls
) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div>
      <b>Stylování slov</b>
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) => (
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </div>
    </div>
  );
};

/**Link Related */
const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const createLinkDecorator = () =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

const linkStyle = {
  fontWeight: "700",
  color: "#BB0003",
  textDecoration: "underline",
};

export const onAddLink = (editorState: EditorState, setEditorState: any) => {
  const linkUrl = window.prompt("Add link http:// ");
  const decorator = createLinkDecorator();
  if (linkUrl) {
    const displayLink = window.prompt("Zobrazený text:");
    const selection = editorState.getSelection();
    if (displayLink && selection.isCollapsed()) {
      const currentContent = editorState.getCurrentContent();
      currentContent.createEntity("LINK", "MUTABLE", {
        url: linkUrl,
      });
      const entityKey = currentContent.getLastCreatedEntityKey();
      const textWithEntity = Modifier.insertText(
        currentContent,
        selection,
        displayLink,
        undefined,
        entityKey
      );
      let newState = EditorState.createWithContent(textWithEntity, decorator);
      setEditorState(newState);
    }
  }
};

var LINK = { label: "Vložit Odkaz", style: "LINK" };

interface ILinkButton {
  editorState: EditorState;
  setEditorState: any;
}

const LinkButton: FunctionComponent<ILinkButton> = (props: ILinkButton) => {
  const onToggle = (e: any) => {
    e.preventDefault();
    onAddLink(props.editorState, props.setEditorState);
  };

  return (
    <span className="RichEditor-styleButton" onMouseDown={onToggle}>
      {LINK.label}
    </span>
  );
};

interface ILink {
  contentState: ContentState;
  entityKey: any;
  children: any;
}

const Link: FunctionComponent<ILink> = (props: ILink) => {
  let { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a style={linkStyle as any} href={url} target="_blank">
      {props.children}
    </a>
  );
};
