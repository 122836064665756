import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { Header, Container, Image } from "semantic-ui-react";
import { IStatistics } from "../lib/library";
import { NavLink } from "react-router-dom";

interface PageLogoProps {
  personalData: IStatistics;
}

const PageLogo: FunctionComponent<PageLogoProps> = (props: PageLogoProps) => {
  return (
    <Container className="logo">
      <Image
        className="logo-img"
        as={NavLink}
        to="/"
        src={`${process.env.PUBLIC_URL}/header_logo.svg`}
        verticalAlign="bottom"
        size="large"
      />
      <Header
        inverted
        as={NavLink}
        exact
        to="/"
        size="large"
        content={props.personalData.pageHeader}
        subheader="Realitní Makléř"
      />
    </Container>
  );
};

PageLogo.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default PageLogo;
