import React, { FunctionComponent } from "react";
import { Container, Tab, Segment } from "semantic-ui-react";
import MessagesEdit from "./MessagesEdit";
import ReferencesEdit from "./ReferencesEdit";
import EstatesEdit from "./EstatesEdit";
import PersonalDataEdit from "./PersonalDataEdit";

const tabs = [
  {
    menuItem: { key: "messages", icon: "mail", content: "Schránka" },
    render: () => <MessagesEdit />,
  },
  {
    menuItem: { key: "references", icon: "star", content: "Reference" },
    render: () => <ReferencesEdit />,
  },
  {
    menuItem: { key: "estates", icon: "home", content: "Katalog Nemovitostí" },
    render: () => <EstatesEdit />,
  },
  {
    menuItem: { key: "personalInfo", icon: "at", content: "Osobní Údaje" },
    render: () => <PersonalDataEdit />,
  },
];

const Tabs: FunctionComponent<{}> = () => {
  return (
    <Container>
      <Segment padded="very" vertical>
        <Tab renderActiveOnly panes={tabs}></Tab>
      </Segment>
    </Container>
  );
};

export default Tabs;
