import React, { FunctionComponent } from "react";
import { Modal, Header } from "semantic-ui-react";
import { IStatistics } from "../lib/library";

interface TermsModalProps {
  children: React.ReactNode;
  isOpened: boolean;
  setIsOpened: (close: boolean) => void;
  personalData: IStatistics;
}

const TermsModal: FunctionComponent<TermsModalProps> = (
  props: TermsModalProps
) => {
  return (
    <Modal
      size="fullscreen"
      trigger={props.children}
      open={props.isOpened}
      centered
      closeIcon
      onClose={() => props.setIsOpened(false)}
    >
      <Modal.Header>
        PROHLÁŠENÍ O OCHRANĚ OSOBNÍCH ÚDAJŮ A SOUBORŮ COOKIE
      </Modal.Header>
      <Modal.Content scrolling>
        <p>
          {props.personalData.pageHeader}, IČO 01858734, se sídlem Žitná 2600,
          530 02 Pardubice jako správce osobních údajů, Vás jako uživatele
          webových stránek www.janjirek.cz informuje o níže popsaném
          shromažďování osobních údajů a zásadách ochrany soukromí ve smyslu
          nařízení Evropského parlamentu a Rady (EU) č. 2016/679 o ochraně
          fyzických osob v souvislosti se zpracováním osobních údajů a o volném
          pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o
          ochraně osobních údajů), dále jen „Nařízení“.
        </p>
        <Header as="h3">1. Zpracování osobních údajů dětí</Header>
        <p>
          Naše webové stránky nejsou určeny pro děti mladší 16 let. Osobní údaje
          dětí mladších 16 let nezpracováváme.
        </p>
        <Header as="h3">2. Rozsah zpracování osobních údajů</Header>
        <p>
          Když nás kontaktujete prostřednictvím webových stránek, můžete být
          požádáni o vyplnění určitých údajů o sobě nebo Vaší společnosti.
          Těmito údaji mohou být zejména:
        </p>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Vaše jméno a příjmení,</li>
          <li>obchodní firma,</li>
          <li>adresa nebo sídlo společnosti,</li>
          <li>identifikační číslo a daňové identifikační číslo,</li>
          <li>telefonní číslo nebo</li>
          <li>e-mailová adresa.</li>
        </ol>
        <p>
          Na našich webových stránkách Vás nesledujeme, takže se k nám dostanou
          jenom ty Vaše osobní údaje, které nám výslovně sdělíte.
        </p>
        <Header as="h3">3. Účel zpracování</Header>
        <p>
          Údaje, které nám poskytujete, používáme k tomu, abychom Vás
          kontaktovali zpět a poskytli Vám informace, o které jste nás požádali,
          nebo pro účely plnění smlouvy, tj. zprostředkování koupě či prodeje
          nemovitosti. Veškeré osobní údaje jsou zpracovány zákonným a
          transparentním způsobem a jsou vyžadovány pouze přiměřené, relevantní
          a nezbytné údaje ve vztahu k účelu zpracování.
        </p>
        <p>
          Vaše jméno, příjmení a e-mailovou adresu můžeme vedle toho využít k
          tomu, abychom Vám zasílali obchodní sdělení,tj. abychom Vás
          informovali o událostech, publikacích nebo službách, které poskytujeme
          a které by Vás, podle našeho názoru, mohly zajímat. Poskytnutí
          osobních údajů pro účely plnění smlouvy a poskytnutí osobních údajů
          pro účely odpovědi na Vámi vznesené dotazy nebo Vámi požadované
          informace jsou naším smluvním požadavkem, a jejich neposkytnutí může
          být důsledkem neuzavření smlouvy nebo neposkytnutí odpovědi na Vámi
          vznesené dotazy
        </p>
        <p>
          Vedle toho zpracování Vašich osobních údajů za účelem zasílání
          obchodních sdělení můžete kdykoliv odmítnout a nebude to mít vliv na
          naše jiné vzájemné vztahy. Stačí nám zaslat e-mail s příslušnou
          žádostí na {props.personalData.email} nebo jinou adresu, ze které jste
          od nás obdržel obchodní sdělení.
        </p>
        <Header as="h3">4. Kdo má k Vašim osobním údajům přístu</Header>
        <p>
          Vaše osobní údaje budeme jako správce zpracovávat My. Vaše osobní
          údaje můžeme za výše uvedenými účely předat našim subdodavatelům, aby
          pro nás provedli jejich zpracování. Osobní údaje mohou být předány:
        </p>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>spolupracujícím realitním makléřům;</li>
          <li>externí advokátní kanceláři;</li>
          <li>
            zpracovatelům, kteří poskytují Zprostředkovateli IT služby, či kteří
            jsou jeho obchodními partnery
          </li>
        </ol>
        <Header as="h3">5. Doba zpracování osobních údajů</Header>
        <p>
          Vaše osobní údaje budeme zpracovávat po dobu, po kterou Vám budeme
          poskytovat naše služby či plnit vzájemnou smlouvu, nebo po dobu
          nezbytnou k plnění archivačních povinností podle platných právních
          předpisů, jako jsou například zákon o účetnictví, zákon o archivnictví
          a evidenci nebo zákon o dani z přidané hodnoty.
        </p>
        <Header as="h3">
          6. Vaše práva plynoucí ze zpracování osobních údajů
        </Header>
        <p>
          Ve vztahu k námi prováděnému zpracování Vašich osobních údajů máte
          následující práva:
        </p>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>právo na přístup k osobním údajům;</li>
          <li>právo na opravu;</li>
          <li>právo na výmaz („právo být zapomenut“);</li>
          <li>právo na omezení zpracování údajů;</li>
          <li>právo vznést námitku proti zpracování; a</li>
          <li>právo podat stížnost na zpracování osobních údajů.</li>
        </ol>
        <p>
          Vaše práva jsou níže vysvětlena, abyste si dokázal/a udělat jasnější
          představu o jejich obsahu. Všechna Vaše práva můžete uplatnit tak, že
          nás kontaktujete na e-mailové adrese {props.personalData.email}{" "}
          Stížnost můžete podat u dozorového úřadu, kterým je Úřad pro ochranu
          osobních údajů.
        </p>
        <p>
          <b>Právo na přístup</b> znamená, že si kdykoliv můžete požádat o naše
          potvrzení, zda osobní údaje, které se Vás týkají, jsou či nejsou
          zpracovávány, a pokud jsou, pak za jakými účely, v jakém rozsahu, komu
          jsou zpřístupněny, jak dlouho je budeme zpracovávat, zda máte právo na
          opravu, výmaz, omezení zpracování či vznést námitku, odkud jsme osobní
          údaje získali a zda dochází k na základě zpracování Vašich osobních
          údajů k automatickému rozhodování, včetně případného profilování. Také
          máte právo získat kopii Vašich osobních údajů, přičemž první
          poskytnutí je bezplatné, za další poskytnutí pak může Zprostředkovatel
          požadovat přiměřenou úhradu administrativních nákladů.
        </p>
        <p>
          <b>Právo na opravu</b> znamená, že nás kdykoliv můžete požádat o
          opravu či doplnění Vašich osobních údajů, pokud by byly nepřesné či
          neúplné.
        </p>
        <p>
          <b>Právo na výmaz</b> znamená, že musíme vymazat Vaše osobní údaje
          pokud (i) již nejsou potřebné pro účely, pro které byly shromážděny
          nebo jinak zpracovány, (ii) zpracování je protiprávní, (iii) vznesete
          námitky proti zpracování a neexistují žádné převažující oprávněné
          důvody pro zpracování, nebo (iv) nám to ukládá zákonná povinnost.
        </p>
        <p>
          <b>Právo na omezení zpracování</b> znamená, že dokud nevyřešíme
          jakékoliv sporné otázky ohledně zpracování Vašich osobních údajů,
          musíme omezit zpracování Vašich osobních údajů tak, že tyto můžeme mít
          pouze uloženy a případně je můžeme použít z důvodu určení, výkonu nebo
          obhajoby právních nároků.
        </p>
        <p>
          <b>Právo vznést námitku</b> znamená, že můžete vznést námitku proti
          zpracování Vašich osobních údajů, které zpracováváme pro účely přímého
          marketingu nebo z důvodu oprávněného zájmu. Pokud vznesete námitku
          proti zpracování pro účely přímého marketingu, nebudou již Vaše osobní
          údaje pro tyto účely zpracovávány.
        </p>
        <Header as="h3">7. Zásada použití souborů cookie</Header>
        <p>
          Správce osobních údajů, jakožto provozovatel webové stránky
          www.janjirek.cz, užívá na této webové stránce soubory cookies, které
          jsou zde užity za účelem:
        </p>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            měření návštěvnosti webových stránek a vytváření statistik týkající
            se návštěvnosti a chování návštěvníků na webových stránkách
          </li>
          <li>základní funkčnosti webových stránek</li>
        </ol>
        <Header as="h3">8. Změny tohoto prohlášení</Header>
        <p>
          Správce osobních údajů, jakožto provozovatel webové stránky
          www.janjirek.cz, si vyhrazuje právo na změnu či doplnění tohoto
          prohlášení, tak aby bylo v souladu s aktuálními obecně právními
          předpisy ochrany osobních údajů.
        </p>
        <Header as="h3">9. Kontakt</Header>
        <p>
          V případě, že budete potřebovat kteroukoliv část textu vysvětlit,
          poradit anebo projednat další zpracování Vašich osobních údajů, můžete
          se na nás kdykoliv obrátit na e-mailové adrese{" "}
          {props.personalData.email}
        </p>
        <p>Tyto Zásady ochrany osobních údajů jsou účinné od 25.5.2018.</p>
      </Modal.Content>
    </Modal>
  );
};

export default TermsModal;
