import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import ResponsiveContainer from "../components/ResponsiveComponent";
import Footer from "../components/Footer";
import {
  Container,
  Segment,
  Header,
  Icon,
  Divider,
  Button,
  Grid,
  Table,
  GridColumn,
  Loader,
  Dimmer,
  Embed,
} from "semantic-ui-react";
import { RouteComponentProps } from "react-router-dom";
import {
  IEstatesHighlight,
  IStatistics,
  createMarkup,
  EstateType,
} from "../lib/library";
import { NavLink } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import InterestButton from "../components/ContactLink";
import { dateFormatter } from "../lib/library";
import { Helmet } from "react-helmet";
import useEstateDetail from "../hooks/useEstateDetail";
declare var window: any;

interface MatchParams {
  id: string;
}

interface EstateDetailProps extends RouteComponentProps<MatchParams> {
  personalData: IStatistics;
}

const EstateDetail: FunctionComponent<EstateDetailProps> = (
  props: EstateDetailProps
) => {
  const { data, isLoading, isFetching } = useEstateDetail(
    props.match.params.id
  );
  const [classMap, setClassMap] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  const getVideoId = (): string => {
    if (data && data.videoLink !== undefined) {
      return data.videoLink.split("=")[1];
    } else return "";
  };

  useEffect(() => {
    const renderMap = (): void => {
      const geocoder = new window.SMap.Geocoder(
        data.area,
        () => {
          if (geocoder.getResults()[0].results.length !== 0) {
            setClassMap("map");
            let coordinates: Array<String> = geocoder
              .getResults()[0]
              .results[0].coords.toWGS84(0);

            const center = window.SMap.Coords.fromWGS84(
              coordinates[0],
              coordinates[1]
            );
            const m = new window.SMap(
              window.JAK.gel(inputRef.current),
              center,
              13
            );
            m.addDefaultLayer(window.SMap.DEF_BASE).enable();
            m.addDefaultControls();

            const layer = new window.SMap.Layer.Marker();
            m.addLayer(layer);
            layer.enable();

            const options = {};
            const marker = new window.SMap.Marker(center, "myMarker", options);
            layer.addMarker(marker);
          }
        },
        { url: "api4.mapy.cz/geocode" }
      );
    };
    if (data && inputRef.current) {
      renderMap();
    }
  }, [props.match.params.id, data]);

  return (
    <div>
      <Helmet>
        <title>Ing. Jan Jírek - Detail Nemovitosti</title>
        <meta itemProp="name" content="Ing. Jan Jírek - Detail Nemovitosti" />
        <meta
          itemProp="description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          itemProp="image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta property="og:url" content="https://www.janjirek.cz" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Ing. Jan Jírek - Detail Nemovitosti"
        />
        <meta
          property="og:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          property="og:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Ing. Jan Jírek - Detail Nemovitosti"
        />
        <meta
          name="twitter:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          name="twitter:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
      </Helmet>
      <ResponsiveContainer isHomepage={false} personalData={props.personalData}>
        <div className="content">
          {data && (
            <>
              <Container>
                <Segment vertical color="red" padded className="header">
                  <Header as="h2">
                    {data.name}
                    <Header>
                      <Header.Subheader>
                        {dateFormatter(data.date)}
                      </Header.Subheader>
                    </Header>
                  </Header>
                </Segment>
                <Grid columns="2" stackable centered>
                  <Grid.Row centered columns="2">
                    <Grid.Column
                      widescreen="5"
                      largeScreen="5"
                      computer="10"
                      tablet="10"
                      mobile="16"
                    >
                      <ImageGallery
                        items={data.images}
                        showPlayButton={false}
                        showNav={true}
                        infinite={true}
                        showBullets={true}
                        isRTL={false}
                        showIndex={true}
                        additionalClass="app-image-gallery"
                      />
                      {data.videoLink !== undefined && (
                        <Embed
                          className="video"
                          source="youtube"
                          id={getVideoId()}
                          placeholder={`https://img.youtube.com/vi/${getVideoId()}/hqdefault.jpg`}
                        />
                      )}

                      <Segment circular inverted className="segmentPrice">
                        <Header as="h2" inverted>
                          Cena
                          <Header.Subheader>{data.price}</Header.Subheader>
                        </Header>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column
                      widescreen="11"
                      largeScreen="11"
                      computer="10"
                      tablet="16"
                      mobile="16"
                    >
                      <Container text>
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            data.description
                          )}
                        />
                      </Container>
                      <Container text>
                        <Table
                          basic="very"
                          celled
                          padded
                          striped
                          verticalAlign="middle"
                          className="detailTable"
                        >
                          <Table.Body>
                            {data.highlights.map((item: IEstatesHighlight) => (
                              <Table.Row key={item.fieldName}>
                                <Table.Cell>
                                  <Header.Subheader>
                                    {item.fieldName}
                                  </Header.Subheader>
                                </Table.Cell>
                                <Table.Cell>{item.fieldValue}</Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </Container>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row verticalAlign="middle" textAlign="center">
                    <GridColumn
                      widescreen="5"
                      largeScreen="5"
                      tablet="16"
                      mobile="16"
                    ></GridColumn>
                    <GridColumn
                      widescreen="10"
                      largeScreen="10"
                      tablet="16"
                      mobile="16"
                    >
                      <div className={classMap} ref={inputRef}></div>
                    </GridColumn>
                  </Grid.Row>
                  <Grid.Row verticalAlign="middle" textAlign="center">
                    <GridColumn
                      widescreen="5"
                      largeScreen="5"
                      tablet="16"
                      mobile="16"
                    ></GridColumn>
                    <GridColumn
                      widescreen="11"
                      largeScreen="11"
                      tablet="16"
                      mobile="16"
                    >
                      {data.type !== EstateType.Sold && (
                        <InterestButton
                          personalData={props.personalData}
                          label="Mám zájem"
                          areSocialNetworks={false}
                          predText={`Mám zájem o nemovitost ${data.name}...`}
                        />
                      )}
                    </GridColumn>
                  </Grid.Row>
                </Grid>
              </Container>
            </>
          )}
          {!data && !isLoading && isFetching && (
            <>
              <Segment vertical className="wrapper">
                <Container>
                  <Container text>
                    <Header as="h2" size="small">
                      <Icon name="window close outline" />
                      <Header.Content>
                        Nemovitost, kterou hledáte, nebyla nalezena. Zkotrolujte
                        si správnost Vašeho odkazu či zda se Vaše hledaná
                        nemovitost stále nachází v seznamu nemovitostí. Použijte
                        tlačítko níže pro návrat na seznam nemovitostí.
                      </Header.Content>
                    </Header>
                    <Divider />
                    <Container textAlign="center">
                      <Button
                        as={NavLink}
                        exact
                        to="/nemovitosti/"
                        icon
                        labelPosition="right"
                        primary
                      >
                        Zpět na seznam nemovitostí
                        <Icon name="list" />
                      </Button>
                    </Container>
                  </Container>
                </Container>
              </Segment>
            </>
          )}
        </div>
        <Footer personalData={props.personalData} />
        <Dimmer active={isLoading || isFetching || !data} page>
          <Loader content="načítání" />
        </Dimmer>
      </ResponsiveContainer>
    </div>
  );
};

EstateDetail.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default EstateDetail;
