import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Icon,
  Dimmer,
  Loader,
  Form,
  Table,
  Header,
  Rating,
  Checkbox,
  Message,
} from "semantic-ui-react";
import {
  IEstates,
  IEstatesHighlight,
  IEstatesImages,
  emptyEstateObject,
  EstateType,
} from "../../lib/library";
import { getEstateDetail, deleteEstate, updateEstate } from "../../lib/axios";
import ConfirmationModal from "./ConfirmationModal";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import ImageGallery from "react-image-gallery";
import RichTextEditor from "./RichEditor";

interface IEstatesImagesAdmin extends IEstatesImages {
  added: boolean;
}

interface IEstatesAdmin extends IEstates {
  images: IEstatesImagesAdmin[];
}

interface EstateModalProps {
  children: React.ReactNode;
  handleCloseModal(index: number): void;
  isOpened: boolean;
  id: string;
  index: number;
  updateFunc: () => Promise<any>;
  isOpenedConfirm: boolean;
  handleCloseConfirm(index: number): void;
  handleOpenConfirm(index: number): void;
}

interface IFilesForUpload {
  file: File;
  fileName: string;
}

const emptyEstateAdminObject: IEstatesAdmin = Object.assign(
  {},
  emptyEstateObject,
  { images: [] }
);

const options = [
  { key: "Sell", value: EstateType.Sell, text: EstateType.Sell },
  { key: "Hire", value: EstateType.Hire, text: EstateType.Hire },
  { key: "Sold", value: EstateType.Sold, text: EstateType.Sold },
];

const EstateDetailModal: FunctionComponent<EstateModalProps> = (
  props: EstateModalProps
) => {
  const [content, setContent] = useState<IEstatesAdmin>(emptyEstateAdminObject);
  const [loading, setLoading] = useState<boolean>(true);
  const [images, setImages] = useState<IFilesForUpload[]>([]);
  const [error, setError] = useState<string>("");

  const getDetailData = async (id: string) => {
    const result: IEstatesAdmin = await getEstateDetail("null", id);
    if (result !== undefined || result !== null) {
      result.images.forEach((element: IEstatesImagesAdmin) => {
        element.added = false;
      });
      setContent(result);
    }
    setLoading(false);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    getDetailData(props.id);
  }, [props.id, loading]);

  const handleChangeDescription = (htmlDescription: string): void => {
    const newItem = {
      description: htmlDescription,
    };
    setContent(Object.assign({}, content, newItem));
  };

  const handleUpdateHighlights = (
    index: number,
    item: IEstatesHighlight
  ): void => {
    const highlightsArray = [...content.highlights];
    highlightsArray[index] = item;
    const updatedState = Object.assign({}, content, {
      highlights: highlightsArray,
    });
    setContent(updatedState);
  };

  const handleDeleteHighlights = (index: number): void => {
    const highlightsArray = [...content.highlights];
    highlightsArray.splice(index, 1);
    const updatedState = Object.assign({}, content, {
      highlights: highlightsArray,
    });
    setContent(updatedState);
  };

  const handleAddHighlight = () => {
    const highlightsArray = [...content.highlights];
    highlightsArray.push({ fieldName: "", fieldValue: "" });
    const updatedState = Object.assign({}, content, {
      highlights: highlightsArray,
    });
    setContent(updatedState);
  };

  const handleUpdateImages = (
    index: number,
    item: IEstatesImagesAdmin
  ): void => {
    const imagesArray = [...content.images];
    imagesArray[index] = item;
    const updatedState = Object.assign({}, content, { images: imagesArray });
    setContent(updatedState);
  };

  const handleDeleteImage = (index: number): void => {
    const imagesArray = [...content.images];
    if (imagesArray[index].added) {
      const uploadedImages = [...images];
      for (let i in images) {
        if (imagesArray[index].name === uploadedImages[i].fileName) {
          uploadedImages.splice(parseInt(i), 1);
          break;
        }
      }
      setImages(uploadedImages);
    }
    imagesArray.splice(index, 1);
    const updatedState = Object.assign({}, content, {
      images: imagesArray,
    });
    setContent(updatedState);
  };

  const handleAddImage = (files: FileList) => {
    const imagesArray = [...content.images];
    for (let i = 0; i < files.length; i++) {
      let base64Image = URL.createObjectURL(files[i]);
      imagesArray.push({
        name: files[i].name,
        priority: 0,
        added: true,
        original: base64Image,
      });
    }
    const updatedState = Object.assign({}, content, {
      images: imagesArray,
    });
    setContent(updatedState);
  };

  const handleVideo = (checked: boolean): void => {
    if (checked) {
      setContent(Object.assign({}, content, { videoLink: "", hasVideo: true }));
    } else {
      const temp = Object.assign({}, content, {
        videoLink: undefined,
        hasVideo: false,
      });
      setContent(temp);
    }
  };

  const handleChangeFiles = (files: FileList) => {
    const updatedFiles: IFilesForUpload[] = [...images];
    for (let i = 0; i < files.length; i++) {
      updatedFiles.push({ file: files[i], fileName: files[i].name });
    }
    setImages(updatedFiles);
    handleAddImage(files);
  };

  const handleOnClick = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("details", JSON.stringify(content));
    images.forEach((item: IFilesForUpload) => {
      formData.append(item.fileName, item.file);
    });
    const result = await updateEstate(content._id || "", formData);
    if (result.status !== 500) {
      setLoading(false);
      props.handleCloseModal(props.index);
      props.updateFunc();
    } else {
      setLoading(false);
      setError(result);
    }
  };
  return (
    <Modal
      trigger={props.children}
      open={props.isOpened}
      closeIcon={
        <Icon
          name="close"
          onClick={() => {
            props.handleCloseModal(props.index);
          }}
        />
      }
      dimmer="blurring"
    >
      <Modal.Header>{content.name}</Modal.Header>
      <Modal.Content>
        <Form>
          {error !== "" && (
            <Message
              error
              header="Upload nemovitosti selhal"
              content={error}
            ></Message>
          )}

          <Header as="h4">Datum přidání či aktualizace</Header>
          <SemanticDatepicker
            locale="en-US"
            value={new Date(content.date)}
            clearable={false}
            format="DD-MM-YYYY"
            datePickerOnly={false}
            onChange={(event, data) => {
              const newItem = {
                date: data.value,
              };
              setContent(Object.assign({}, content, newItem));
            }}
          />
          <Form.Input
            control="input"
            label="Název Nemovitosti"
            placeholder="Zadejte název nemovitosti"
            value={content.name}
            onChange={(event) => {
              const target = event.target as HTMLInputElement;
              const newItem = {
                name: target.value,
              };
              setContent(Object.assign({}, content, newItem));
            }}
          />
          <Form.Input
            control="input"
            label="Cena"
            placeholder="Zadejte cenu nemovitosti"
            value={content.price}
            onChange={(event) => {
              const target = event.target as HTMLInputElement;
              const newItem = {
                price: target.value,
              };
              setContent(Object.assign({}, content, newItem));
            }}
          />
          <Form.Input
            control="input"
            label="Lokace"
            placeholder="Zadejte oblast, adresu, či město, kde se nemovitost nachází"
            value={content.area}
            onChange={(event) => {
              const target = event.target as HTMLInputElement;
              const newItem = {
                area: target.value,
              };
              setContent(Object.assign({}, content, newItem));
            }}
          />
          <Form.Select
            label="Typ"
            placeholder="Vyberte typ"
            options={options}
            value={content.type}
            onChange={(event, { value }) => {
              const newItem = {
                type: value,
              };
              setContent(Object.assign({}, content, newItem));
            }}
          />
          <div className="videoCheckbox">
            <Checkbox
              label="Má nemovitost video?"
              onChange={(e: React.FormEvent<HTMLInputElement>, data) => {
                const target = data.checked;
                handleVideo(target || false);
              }}
              checked={content.hasVideo}
            />
          </div>
          {content.videoLink !== undefined && (
            <Form.Input
              control="input"
              label="Odkaz na Youtube"
              placeholder="Zadejte odkaz na Youtube, kde je nahrané Vaše video."
              value={content.videoLink}
              onChange={(event) => {
                const target = event.target as HTMLInputElement;
                const newItem = {
                  videoLink: target.value,
                };
                setContent(Object.assign({}, content, newItem));
              }}
            />
          )}
          <b className="label">Popis Nemovitosti</b>
          <RichTextEditor
            description={content.description}
            handleUpdateDescription={handleChangeDescription}
          />
          <Table
            celled
            verticalAlign="middle"
            padded
            striped
            selectable
            columns="3"
            color="red"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  Podrobnosti o Nemovitosti
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {content.highlights.map(
                (item: IEstatesHighlight, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <Form.Input
                        control="input"
                        label="Název pole"
                        placeholder="Zadejte název pro udanou vlastnost nemovitosti."
                        value={item.fieldName}
                        onChange={(event) => {
                          const target = event.target as HTMLInputElement;
                          const newItem = Object.assign({}, item, {
                            fieldName: target.value,
                            fieldValue: item.fieldValue,
                          });
                          handleUpdateHighlights(index, newItem);
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Input
                        control="input"
                        label="Vlastnost Nemovitosti"
                        placeholder="Zadejte vlastnost nemovitosti."
                        value={item.fieldValue}
                        onChange={(event) => {
                          const target = event.target as HTMLInputElement;
                          const newItem = Object.assign({}, item, {
                            fieldName: item.fieldName,
                            fieldValue: target.value,
                          });
                          handleUpdateHighlights(index, newItem);
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell width="2" textAlign="center">
                      <Button
                        negative
                        icon="trash"
                        labelPosition="right"
                        content="Smazat"
                        compact
                        onClick={() => handleDeleteHighlights(index)}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Button
                    primary
                    icon="plus"
                    labelPosition="right"
                    content="Přidat Vlastnost"
                    onClick={handleAddHighlight}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Table
            celled
            verticalAlign="middle"
            padded
            striped
            selectable
            columns="3"
            color="red"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  Galerie (obrázek s největší prioritou bude použit jako hlavní)
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {content.images.map(
                (item: IEstatesImagesAdmin, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <ImageGallery
                        items={[item]}
                        showPlayButton={false}
                        showNav={false}
                        infinite={false}
                        showBullets={false}
                        isRTL={false}
                        showIndex={false}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <div>Priorita: {item.priority}</div>
                        <Form.Input
                          type="range"
                          min={0}
                          max={10}
                          value={item.priority}
                          onChange={(event) => {
                            const target = event.target as HTMLInputElement;
                            const newItem = Object.assign({}, item, {
                              priority: parseInt(target.value),
                            });
                            handleUpdateImages(index, newItem);
                          }}
                        ></Form.Input>
                      </div>
                      <Rating disabled rating={item.priority} maxRating={10} />
                    </Table.Cell>
                    <Table.Cell width="2" textAlign="center">
                      <Button
                        negative
                        icon="trash"
                        labelPosition="right"
                        content="Smazat"
                        compact
                        onClick={() => handleDeleteImage(index)}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Button
                    compact
                    primary
                    icon="plus"
                    labelPosition="right"
                    content="Nahrát Obrázky"
                    onClick={() => inputRef.current?.click()}
                  />
                  <input
                    type="file"
                    ref={inputRef}
                    hidden
                    multiple
                    accept="image/png, image/jpeg"
                    onChange={(event) => {
                      const files = event.currentTarget.files;
                      if (files !== null) {
                        handleChangeFiles(files);
                      }
                    }}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <ConfirmationModal
          id={content._id}
          isOpened={props.isOpenedConfirm}
          handleCloseModal={() => props.handleCloseConfirm(props.index)}
          header="Smazat Nemovitost"
          message="Opravdu chcete vymazat nemotivost z katalogu? Operace je nevratná!"
          handleCloseParent={() => props.handleCloseModal(props.index)}
          databaseAction={deleteEstate}
          updateFunc={props.updateFunc}
        >
          <Button
            negative
            icon="trash"
            labelPosition="left"
            content="Smazat Nemovitost"
            onClick={() => props.handleOpenConfirm(props.index)}
          />
        </ConfirmationModal>
        <Button
          disabled={content.name.length === 0}
          primary
          icon="cloud upload"
          labelPosition="left"
          content="Aktualizovat Nemovitost"
          onClick={handleOnClick}
        />
      </Modal.Actions>
      <Dimmer active={loading}>
        <Loader content="načítání" />
      </Dimmer>
    </Modal>
  );
};

export default EstateDetailModal;
