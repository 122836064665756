import React from "react";
import { Segment, Grid, Header, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { personalDetails, IServices } from "../lib/library";

const Squares = () => {
  return (
    <Segment vertical>
      <Grid celled="internally" columns="equal" stackable>
        <Grid.Row textAlign="center">
          {personalDetails.services.map((item: IServices) => (
            <Grid.Column as={NavLink} to="/sluzby" key={item.name}>
              <Header as="h2" icon textAlign="center">
                <Icon name={item.icon} color="red" circular />
                <Header.Content>{item.name}</Header.Content>
                <Header.Subheader className="squares">
                  {item.subheader}
                </Header.Subheader>
              </Header>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default Squares;
