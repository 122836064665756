import React, { FunctionComponent } from "react";
import { Modal, Button } from "semantic-ui-react";

interface ConfirmModalProps {
  message: string;
  header: string;
  children: React.ReactNode;
  databaseAction(id?: string): Promise<any>;
  handleCloseModal(): void;
  isOpened: boolean;
  index?: number;
  handleCloseParent?: (index: number) => void;
  id?: string;
  updateFunc?: () => Promise<any>;
}

const ConfirmModal: FunctionComponent<ConfirmModalProps> = (
  props: ConfirmModalProps
) => {
  const handleOnClick = async () => {
    if (props.handleCloseParent) {
      props.handleCloseParent(props.index || 0);
    }
    await props.databaseAction(props.id);
    if (props.updateFunc !== undefined) {
      props.updateFunc();
    }
    props.handleCloseModal();
  };
  return (
    <Modal size="small" trigger={props.children} open={props.isOpened} centered>
      <Modal.Header>{props.header}</Modal.Header>
      <Modal.Content>
        <p>{props.message}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={props.handleCloseModal}>
          Ne
        </Button>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="Ano"
          onClick={handleOnClick}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmModal;
