import React, { FunctionComponent, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dimmer, Loader } from "semantic-ui-react";
import ResponsiveContainer from "../components/ResponsiveComponent";
import Footer from "../components/Footer";
import { checkToken } from "../lib/axios";
import { Redirect } from "react-router-dom";
import Tabs from "../components/protected/Tabs";
import { IStatistics } from "../lib/library";

interface IProtectedPage {
  loading: boolean;
  authorized: boolean;
}

interface AdminPageProps {
  personalData: IStatistics;
}

const AdminPage: FunctionComponent<AdminPageProps> = (
  props: AdminPageProps
) => {
  const [status, setStatus] = useState<IProtectedPage>({
    loading: false,
    authorized: true,
  });

  useEffect(() => {
    const authorization = async () => {
      const result = await checkToken();
      if (result === 200) {
        setStatus(Object.assign({}, { authorized: true, loading: false }));
      } else {
        setStatus(Object.assign({}, { authorized: false, loading: false }));
      }
    };
    authorization();
  }, []);

  return (
    <ResponsiveContainer isHomepage={false} personalData={props.personalData}>
      <div className="content">
        <>
          {status.loading && <div></div>}
          {!status.authorized && <Redirect exact to="/login" />}
          {status.authorized && <Tabs />}
        </>
        <Dimmer active={status.loading} page>
          <Loader content="načítání" />
        </Dimmer>
      </div>
      <Footer personalData={props.personalData} />
    </ResponsiveContainer>
  );
};

AdminPage.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default AdminPage;
