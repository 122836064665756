import React, { FunctionComponent, useState, useEffect } from "react";
import { Tab, List, Image, Button } from "semantic-ui-react";
import {
  IEstatesList,
  dateFormatterWithTime,
  emptyEstateObject,
} from "../../lib/library";
import { getEstateList, addEstate } from "../../lib/axios";
import EstateDetailModal from "./EstateDetailEdit";

interface IAdminEstatesData extends IEstatesList {
  IsModalOpen: boolean;
  IsOpenConfirm: boolean;
}

const Estates: FunctionComponent<{}> = () => {
  const [content, setContent] = useState<IAdminEstatesData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleCloseMainModal = (index: number): void => {
    const state = [...content];
    const stateItem = state[index];
    stateItem.IsModalOpen = false;
    setContent(state);
  };

  const handleCloseConfirmModal = (index: number): void => {
    const state = [...content];
    const stateItem = state[index];
    stateItem.IsOpenConfirm = false;
    setContent(state);
  };

  const handleOpenMainModal = (index: number): void => {
    const state = [...content];
    const stateItem = state[index];
    stateItem.IsModalOpen = true;
    setContent(state);
  };

  const handleOpenConfirmModal = (index: number): void => {
    const state = [...content];
    const stateItem = state[index];
    stateItem.IsOpenConfirm = true;
    setContent(state);
  };

  const handleAddEstate = async () => {
    setLoading(true);
    const result = await addEstate(emptyEstateObject);
    if (result === 200) {
      getData(true);
    }
  };

  const getData = async (added?: boolean) => {
    const result = await getEstateList();
    if (result instanceof Array) {
      result.forEach((element: IAdminEstatesData, index: number) => {
        if (added && index === 0) {
          element.IsModalOpen = true;
        } else {
          element.IsModalOpen = false;
        }
        element.IsOpenConfirm = false;
      });
      setContent(result);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Tab.Pane loading={loading}>
      <List selection relaxed divided>
        <List.Item>
          <Button
            primary
            icon="cloud upload"
            labelPosition="left"
            content="Přidat Nemovitost do Katalogu"
            onClick={handleAddEstate}
            className="addEstateButton"
          />
        </List.Item>
        {content.map((item: IAdminEstatesData, index: number) => (
          <EstateDetailModal
            id={item._id}
            key={item._id}
            handleCloseModal={() => handleCloseMainModal(index)}
            handleCloseConfirm={() => handleCloseConfirmModal(index)}
            handleOpenConfirm={() => handleOpenConfirmModal(index)}
            isOpenedConfirm={item.IsOpenConfirm}
            index={index}
            isOpened={item.IsModalOpen}
            updateFunc={getData}
          >
            <List.Item
              key={item._id}
              onClick={() => {
                handleOpenMainModal(index);
              }}
            >
              <Image src={item.thumbnail} centered size="small" />
              <List.Content>
                <List.Header>{item.name}</List.Header>
                <List.Description>
                  <p>{dateFormatterWithTime(new Date(item.date))}</p>
                  <p>
                    {item.area}, {item.price}, {item.type}
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
          </EstateDetailModal>
        ))}
      </List>
    </Tab.Pane>
  );
};

export default Estates;
