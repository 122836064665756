import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { Responsive } from "semantic-ui-react";
import DesktopPageHeader from "./DesktopHeader";
import { getWidth, IStatistics } from "../lib/library";

interface DesktopContainerProps {
  children: React.ReactNode;
  isHomepage: boolean;
  mobile: boolean;
  personalData: IStatistics;
}

const DesktopContainer: FunctionComponent<DesktopContainerProps> = (
  props: DesktopContainerProps
) => {
  return (
    <Responsive
      getWidth={getWidth}
      minWidth={Responsive.onlyTablet.minWidth}
      className="site"
    >
      <DesktopPageHeader
        isHomepage={props.isHomepage}
        mobile={props.mobile}
        personalData={props.personalData}
      />
      {props.children}
    </Responsive>
  );
};

DesktopContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isHomepage: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  personalData: PropTypes.any.isRequired,
};

export default DesktopContainer;
