import { getEstateDetail } from "../lib/axios";
import { useQuery } from "react-query";

interface QueryResultSet {
  data: any;
  isLoading: boolean;
  isFetching: boolean;
}

const useEstateDetail = (id: string): QueryResultSet => {
  const { data, isLoading, isFetching } = useQuery(
    ["estateDetail", id],
    getEstateDetail,
    {
      staleTime: 1000000,
    }
  );
  return { data, isLoading, isFetching };
};

export default useEstateDetail;
