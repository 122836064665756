import React, { FunctionComponent } from "react";
import {
  Segment,
  Container,
  Icon,
  Header,
  Divider,
  Button,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";

const EstatesCatalogLink: FunctionComponent<{}> = () => {
  return (
    <Segment inverted className="aboutWrapper" vertical>
      <Container className="catalogButton">
        <Container className="carousel" textAlign="center">
          <Header as="h2" className="car">
            <Icon color="grey" name="book" />
            <Header.Content>Nalezněte svoji vysněnou nemovitost</Header.Content>
          </Header>
          <Divider />
          <Button
            size="massive"
            as={NavLink}
            to="/nemovitosti"
            className="catalogLink"
          >
            <Icon name="home" />
            Katalog Nemovitostí
          </Button>
        </Container>
      </Container>
    </Segment>
  );
};

export default EstatesCatalogLink;
