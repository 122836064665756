import React, { useState, FunctionComponent } from "react";
import PropTypes from "prop-types";
import DesktopMenu from "./DesktopMenu";
import { Container, Visibility, Segment, Grid } from "semantic-ui-react";
import PageLogo from "./PageLogo";
import { IStatistics } from "../lib/library";

interface headerProps {
  isHomepage: boolean;
  mobile: boolean;
  personalData: IStatistics;
}

const DesktopHeader: FunctionComponent<headerProps> = (props: headerProps) => {
  const [fixed, setFixed] = useState<boolean>(false);

  const showFixedMenu = (): void => setFixed(true);
  const hideFixedMenu = (): void => setFixed(false);
  const setClassName = (): string => {
    if (props.isHomepage) {
      return "homepage";
    } else {
      return "other";
    }
  };

  return (
    <Visibility
      once={false}
      onBottomPassed={showFixedMenu}
      onBottomPassedReverse={hideFixedMenu}
    >
      <Segment className={setClassName()} inverted textAlign="center" vertical>
        <Container>
          <Grid columns="2">
            <Grid.Row stretched>
              <Grid.Column
                largeScreen="3"
                widescreen="3"
                tablet="16"
                computer="16"
              >
                <PageLogo personalData={props.personalData} />
              </Grid.Column>
              <Grid.Column
                tablet="16"
                computer="16"
                widescreen="13"
                largeScreen="13"
              >
                <DesktopMenu
                  isFixed={fixed}
                  mobile={props.mobile}
                  personalData={props.personalData}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </Visibility>
  );
};

DesktopHeader.propTypes = {
  isHomepage: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  personalData: PropTypes.any.isRequired,
};

export default DesktopHeader;
