import React, { FunctionComponent } from "react";
import {
  Grid,
  Header,
  Image,
  Container,
  Segment,
  Button,
  Icon,
  Label,
  Dimmer,
  Loader,
  Divider,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import {
  IEstatesList,
  EstateType,
  dateFormatter,
  createMarkup,
} from "../lib/library";
import useEstates from "../hooks/useEstates";

const EstatesList: FunctionComponent<{}> = () => {
  const { data, isLoading, isFetching } = useEstates();

  const estateTypesDeterminator = (list: IEstatesList[]): number => {
    let activeEstates = 0;
    let soldEstates = 0;
    list &&
      list.forEach((estate) => {
        if (
          estate.type === EstateType.Hire ||
          estate.type === EstateType.Sell
        ) {
          activeEstates += 1;
        } else {
          soldEstates += 1;
        }
      });
    if (soldEstates > 0 && activeEstates === 0) {
      return 0;
    }
    if (soldEstates > 0 && activeEstates > 0) {
      return 1;
    }
    if (activeEstates === 0) {
      return 3;
    }
    return 2;
  };

  return (
    <>
      {data && data.length === 0 && (
        <Segment raised>
          Děkuji Vám za zájem, ale v současné době se zde nenachází žádné
          nemovitosti.
        </Segment>
      )}
      {(estateTypesDeterminator(data) === 1 ||
        estateTypesDeterminator(data) === 2) &&
        estateTypesDeterminator(data) !== 3 && (
          <Segment color="red" circular className="segmentEstates">
            <Header as="h3" color="red">
              <Icon name="tags" />
              Nemovitosti k Dispozici
            </Header>
          </Segment>
        )}
      {data &&
        data.map(
          (item: IEstatesList) =>
            (item.type === EstateType.Sell ||
              item.type === EstateType.Hire) && (
              <Segment raised key={item._id}>
                <Label color="red" ribbon="right" className="priceTag">
                  {item.price}
                </Label>
                <Grid columns="2" divided="vertically" key={item._id}>
                  <Grid.Row className="estateListHeader">
                    <Grid.Column
                      widescreen="16"
                      largeScreen="16"
                      tablet="16"
                      mobile="16"
                      as={NavLink}
                      exact
                      to={`/nemovitosti/${item._id}`}
                    >
                      <Header size="large" as="h2">
                        <Header.Content>{item.name}</Header.Content>
                        <Label tag color="red" className="tag">
                          {item.type}
                        </Label>
                        <Label tag color="blue" className="tag">
                          {item.area}
                        </Label>
                        <Header.Subheader>
                          {dateFormatter(item.date)}
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column
                      widescreen="6"
                      largeScreen="6"
                      tablet="16"
                      mobile="16"
                      textAlign="center"
                    >
                      <Image
                        as={NavLink}
                        exact
                        to={`/nemovitosti/${item._id}`}
                        src={item.thumbnail}
                        centered
                        size="large"
                      />
                    </Grid.Column>
                    <Grid.Column
                      widescreen="10"
                      largeScreen="10"
                      tablet="16"
                      mobile="16"
                    >
                      <Container text className="shortDescription">
                        {
                          <div
                            dangerouslySetInnerHTML={createMarkup(
                              item.description
                            )}
                          />
                        }
                      </Container>
                      <Segment vertical textAlign="center">
                        <Button
                          as={NavLink}
                          to={`/nemovitosti/${item._id}`}
                          icon
                          labelPosition="right"
                          primary
                        >
                          Více informací
                          <Icon name="arrow right" />
                        </Button>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            )
        )}
      {estateTypesDeterminator(data) === 1 && <Divider />}
      {(estateTypesDeterminator(data) === 0 ||
        estateTypesDeterminator(data) === 1) && (
        <Segment color="red" circular className="segmentEstates">
          <Header as="h3" color="red">
            <Icon name="handshake outline" />
            Prodané Nemovitosti
          </Header>
        </Segment>
      )}
      {data &&
        data.map(
          (item: IEstatesList) =>
            item.type === EstateType.Sold && (
              <Segment raised key={item._id}>
                <Label color="red" ribbon="right" className="priceTag">
                  {item.price}
                </Label>
                <Grid columns="2" divided="vertically" key={item._id}>
                  <Grid.Row className="estateListHeader">
                    <Grid.Column
                      widescreen="16"
                      largeScreen="16"
                      tablet="16"
                      mobile="16"
                      as={NavLink}
                      exact
                      to={`/nemovitosti/${item._id}`}
                    >
                      <Header size="large" as="h2">
                        <Header.Content>{item.name}</Header.Content>
                        <Label tag color="red" className="tag">
                          {item.type}
                        </Label>
                        <Label tag color="blue" className="tag">
                          {item.area}
                        </Label>
                        <Header.Subheader>
                          {dateFormatter(item.date)}
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column
                      widescreen="6"
                      largeScreen="6"
                      tablet="16"
                      mobile="16"
                      textAlign="center"
                    >
                      <Image
                        as={NavLink}
                        exact
                        to={`/nemovitosti/${item._id}`}
                        src={item.thumbnail}
                        centered
                        size="large"
                      />
                    </Grid.Column>
                    <Grid.Column
                      widescreen="10"
                      largeScreen="10"
                      tablet="16"
                      mobile="16"
                    >
                      <Container text className="shortDescription">
                        {
                          <div
                            dangerouslySetInnerHTML={createMarkup(
                              item.description
                            )}
                          />
                        }
                      </Container>
                      <Segment vertical textAlign="center">
                        <Button
                          as={NavLink}
                          to={`/nemovitosti/${item._id}`}
                          icon
                          labelPosition="right"
                          primary
                        >
                          Více informací
                          <Icon name="arrow right" />
                        </Button>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            )
        )}
      <Dimmer active={isLoading || isFetching || !data} page>
        <Loader content="načítání" />
      </Dimmer>
    </>
  );
};

export default EstatesList;
