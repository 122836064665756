import React, { FunctionComponent, useState, useEffect } from "react";
import { Tab, List, Button, Form, Grid } from "semantic-ui-react";
import { IStatistics } from "../../lib/library";
import {
  getStatistics,
  updateStatistics,
  addStatistics,
} from "../../lib/axios";

const PersonalDataEdit: FunctionComponent<{}> = () => {
  const [content, setContent] = useState<IStatistics[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async () => {
    const result = await getStatistics();
    if (result instanceof Array) {
      setContent(result);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await updateStatistics(content[0]._id || "", content[0]);
    getData();
  };

  const handleUpdateState = (item: IStatistics): void => {
    const state = [...content];
    const updatedStateItem = item;
    state[0] = updatedStateItem;
    setContent(state);
  };

  const handleAddNewStatistics = async () => {
    const newSt: IStatistics = {
      clients: 0,
      experience: 0,
      realEstates: 0,
      pageHeader: "",
      phone: "",
      facebook: "",
      instagram: "",
      linkedIn: "",
      place: "",
      email: "",
      ico: "",
    };
    setLoading(true);
    const result = await addStatistics(newSt);
    if (result === 200) {
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Tab.Pane loading={loading}>
      <List selection relaxed divided>
        <List.Item>
          <List.Content>
            <Grid stackable columns="2" className="adminRefGrid">
              <Grid.Row columns="2">
                <Grid.Column width="3">
                  <Button
                    primary
                    icon="cloud upload"
                    labelPosition="left"
                    content="Uložit Změny"
                    onClick={handleSubmit}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    secondary
                    icon="search plus"
                    labelPosition="left"
                    content="Založit Novou Statistiku"
                    disabled={content.length === 1}
                    onClick={handleAddNewStatistics}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </List.Content>
        </List.Item>
        {content.length !== 0 && (
          <List.Item>
            <List.Content>
              <Form className="editRefForm">
                <Form.Input
                  control="input"
                  type="number"
                  label="Počet let v oboru"
                  placeholder="Zadejte počet let v oboru"
                  value={content[0].experience}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      experience: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
                <Form.Input
                  control="input"
                  type="number"
                  label="Počet zobchodovaných nemovitostí"
                  placeholder="Zadejte počet zobchodovaných nemovitostí"
                  value={content[0].realEstates}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      realEstates: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
                <Form.Input
                  control="input"
                  type="number"
                  label="Počet klientů"
                  placeholder="Zadejte počet klientů"
                  value={content[0].clients}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      clients: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
                <Form.Input
                  control="input"
                  label="Celé Jméno"
                  placeholder="Zadejte Vaše celé jméno i s titulem"
                  value={content[0].pageHeader}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      pageHeader: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
                <Form.Input
                  control="input"
                  label="Telefon"
                  placeholder="Zadejte Váš telefon ve formátu +420 000 000 000"
                  value={content[0].phone}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      phone: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
                <Form.Input
                  control="input"
                  label="Facebook"
                  placeholder="Zadejte odkaz na Facebook. (Nechte prázdné pokud nemáte)"
                  value={content[0].facebook}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      facebook: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
                <Form.Input
                  control="input"
                  label="Instagram"
                  placeholder="Zadejte odkaz na Instagram. (Nechte prázdné pokud nemáte)"
                  value={content[0].instagram}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      instagram: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
                <Form.Input
                  control="input"
                  label="LinkedIn"
                  placeholder="Zadejte odkaz na LinkedIn. (Nechte prázdné pokud nemáte)"
                  value={content[0].linkedIn}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      linkedIn: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
                <Form.Input
                  control="input"
                  label="Email"
                  placeholder="Zadejte Vaši emailovou adresu."
                  value={content[0].email}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      email: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
                <Form.Input
                  control="input"
                  label="Oblast působení"
                  placeholder="Zadejte oblast působení, např. Pardubický kraj."
                  value={content[0].place}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      place: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
                <Form.Input
                  control="input"
                  label="IČO"
                  placeholder="Zadejte svoje IČO."
                  value={content[0].ico}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    const newItem = Object.assign({}, content[0], {
                      ico: target.value,
                    });
                    handleUpdateState(newItem);
                  }}
                />
              </Form>
            </List.Content>
          </List.Item>
        )}
      </List>
    </Tab.Pane>
  );
};

export default PersonalDataEdit;
