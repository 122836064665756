import React, { FunctionComponent } from "react";
import Slider from "react-slick";
import { IReference } from "../lib/library";
import {
  Segment,
  Container,
  Icon,
  Dimmer,
  Loader,
  Header,
  Divider,
} from "semantic-ui-react";
import useReferences from "../hooks/useReferences";

const Carousel: FunctionComponent<{}> = () => {
  const { data, isLoading, isFetching } = useReferences();

  const slickSettings = {
    autoplay: true,
    dots: true,
    speed: 500,
    autoplaySpeed: 5000,
    fade: true,
    adaptiveHeight: true,
    customPaging: function(i: any) {
      return (
        <a href="/#">
          <Icon name="window minimize" color="red" />
        </a>
      );
    },
  };

  return (
    <Segment inverted className="carouselWrapper" vertical>
      <Container className="carousel" textAlign="center">
        <Header as="h2" className="car">
          <Icon color="grey" name="eye" />
          <Header.Content>Reference</Header.Content>
        </Header>
        <Divider />
        <Slider {...slickSettings} className="slider">
          {data &&
            data.map((item: IReference) => (
              <Segment
                vertical
                key={item._id}
                className="carousel"
                textAlign="center"
              >
                <Container text className="active" fluid>
                  <Icon
                    className="carInnerText"
                    fitted
                    name="quote left"
                    color="grey"
                  />
                  <i className="carouselText">{item.message}</i>
                  <Icon
                    className="carInnerText"
                    fitted
                    name="quote right"
                    color="grey"
                  />
                  <div>
                    <b className="carInnerText">
                      {item.name} {item.surname}
                    </b>
                  </div>
                </Container>
              </Segment>
            ))}
        </Slider>
      </Container>
      <Dimmer active={isFetching || isLoading || !data} page>
        <Loader content="načítání" />
      </Dimmer>
    </Segment>
  );
};

export default Carousel;
