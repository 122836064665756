import React, { FunctionComponent } from "react";
import MenuItem from "./MenuItem";
import { NavLink } from "react-router-dom";
import { Menu, Icon, Container, Responsive } from "semantic-ui-react";
import PropTypes from "prop-types";
import { routes, IStatistics } from "../lib/library";
import SocialNetwork from "./SocialNetwork";
interface staticMenuProps {
  isFixed: boolean;
  mobile: boolean;
  personalData: IStatistics;
}

const StaticMenu: FunctionComponent<staticMenuProps> = (
  props: staticMenuProps
) => {
  const setStyle = (): string => {
    if (props.isFixed) {
      return "float";
    } else return "";
  };
  return (
    <Menu
      className={setStyle()}
      fixed={props.isFixed ? "top" : undefined}
      inverted={true}
      pointing={!props.isFixed}
      secondary={!props.isFixed}
      size="large"
    >
      <Container>
        <Menu.Item as={NavLink} exact to="/">
          Úvodní stránka
        </Menu.Item>
        {routes.map((item) => (
          <MenuItem key={item.path} path={item.path} name={item.name} />
        ))}

        <Menu.Item position="right">
          <Responsive minWidth={Responsive.onlyComputer.minWidth}>
            <Icon name="phone square" inverted size="big" />
            <a
              href={`tel:${props.personalData.phone}`}
              className="telNumber"
              id="customFooterLink"
            >
              {props.personalData.phone}
            </a>
            <SocialNetwork inverted personalData={props.personalData} />
          </Responsive>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

StaticMenu.propTypes = {
  isFixed: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  personalData: PropTypes.any.isRequired,
};

export default StaticMenu;
