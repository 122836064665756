import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import ResponsiveContainer from "../components/ResponsiveComponent";
import Footer from "../components/Footer";
import { Container, Dimmer, Loader, Card, Icon } from "semantic-ui-react";
import { IReference, IStatistics } from "../lib/library";
import { Helmet } from "react-helmet";
import useReferences from "../hooks/useReferences";

interface ReferenceProps {
  personalData: IStatistics;
}

const References: FunctionComponent<ReferenceProps> = (
  props: ReferenceProps
) => {
  const { data, isLoading, isFetching } = useReferences();

  return (
    <div>
      <Helmet>
        <title>Ing. Jan Jírek - Reference</title>
        <meta itemProp="name" content="Ing. Jan Jírek - Reference" />
        <meta
          itemProp="description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          itemProp="image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta property="og:url" content="https://www.janjirek.cz" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ing. Jan Jírek - Reference" />
        <meta
          property="og:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          property="og:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ing. Jan Jírek - Reference" />
        <meta
          name="twitter:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          name="twitter:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
      </Helmet>
      <ResponsiveContainer isHomepage={false} personalData={props.personalData}>
        <div className="content">
          <Container className="referenceContainer">
            {data &&
              data.map((item: IReference) => (
                <Card fluid key={item._id} color="red">
                  <Card.Content>
                    <Card.Description>
                      <i className="referenceText">
                        <Icon
                          className="carInnerText"
                          fitted
                          name="quote left"
                          color="red"
                          size="tiny"
                        />
                        {item.message}
                        <Icon
                          className="carInnerText"
                          fitted
                          name="quote right"
                          color="red"
                          size="tiny"
                        />
                      </i>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Icon name="user" />
                    {item.name} {item.surname}
                  </Card.Content>
                </Card>
              ))}
          </Container>
        </div>
        <Footer personalData={props.personalData} />
        <Dimmer active={isLoading || isFetching || !data} page>
          <Loader content="načítání" />
        </Dimmer>
      </ResponsiveContainer>
    </div>
  );
};

References.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default References;
