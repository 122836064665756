import React, { FunctionComponent, useState, FormEvent } from "react";
import PropTypes from "prop-types";
import { Grid, Header, Form, Segment, Container } from "semantic-ui-react";
import ResponsiveContainer from "../components/ResponsiveComponent";
import Footer from "../components/Footer";
import { postLogin } from "../lib/axios";
import { ILoginData, IStatistics } from "../lib/library";
import { Redirect } from "react-router-dom";

interface ILoginStateData {
  username: string;
  password: string;
  invalid: boolean;
  authorized: boolean;
}

interface LoginProps {
  personalData: IStatistics;
}

const Login: FunctionComponent<LoginProps> = (props: LoginProps) => {
  const defaultLogin: ILoginStateData = {
    username: "",
    password: "",
    invalid: false,
    authorized: false,
  };
  const [loginData, setLoginData] = useState<ILoginStateData>(defaultLogin);
  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const credentials: ILoginData = {
      username: loginData.username,
      password: loginData.password,
    };
    const makeCall = async () => {
      const result = await postLogin(credentials);
      if (result === 200) {
        setLoginData(Object.assign({}, loginData, { authorized: true }));
      } else {
        setLoginData(Object.assign({}, loginData, { invalid: true }));
      }
    };
    makeCall();
  };

  return (
    <ResponsiveContainer isHomepage={false} personalData={props.personalData}>
      <div className="content">
        <Container text>
          <Grid textAlign="center" verticalAlign="middle" className="login">
            <Grid.Column>
              <Header as="h2" color="red" textAlign="center">
                Přihlášení do administrace
              </Header>
              <Form size="large" onSubmit={handleLogin}>
                <Segment stacked>
                  <Form.Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="přihlašovací jméno"
                    value={loginData.username}
                    onChange={(event) => {
                      const target = event.target as HTMLInputElement;
                      setLoginData(
                        Object.assign({}, loginData, { username: target.value })
                      );
                    }}
                    error={
                      loginData.invalid && {
                        content:
                          "Zadané přihlašovací jméno nebo heslo není správné.",
                        pointing: "below",
                      }
                    }
                  />
                  <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="heslo"
                    type="password"
                    value={loginData.password}
                    onChange={(event) => {
                      const target = event.target as HTMLInputElement;
                      setLoginData(
                        Object.assign({}, loginData, { password: target.value })
                      );
                    }}
                    error={loginData.invalid}
                  />
                  <Form.Button color="red" type="submit" fluid size="large">
                    Přihlášení
                  </Form.Button>
                </Segment>
              </Form>
            </Grid.Column>
          </Grid>
        </Container>
        {loginData.authorized && (
          <Redirect exact to="/7397efa4468a56b65ccde3218e6f798c" />
        )}
      </div>
      <Footer personalData={props.personalData} />
    </ResponsiveContainer>
  );
};

Login.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default Login;
