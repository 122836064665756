import React, { FunctionComponent } from "react";
import {
  Container,
  Grid,
  Header,
  Icon,
  Segment,
  Step,
} from "semantic-ui-react";
import { personalDetails, IServices } from "../lib/library";
import InterestButton from "../components/ContactLink";

const ServiceItem: FunctionComponent<{}> = () => {
  return (
    <Grid columns="2" divided="vertically" stackable>
      {personalDetails.services.map((item: IServices) => (
        <Grid.Row key={item.name}>
          <Grid.Column widescreen="16" largeScreen="16" tablet="16" mobile="16">
            <Container text className="aboutMe">
              <Header as="h2">
                {item.name}
                <Header.Subheader>{item.subheader}</Header.Subheader>
              </Header>
              <p>{item.description}</p>
              {item.points.map((item: string) => (
                <div className="serviceItemBullet" key={Math.random()}>
                  <Icon name="check circle outline" color="red" size="big" />
                  <b>{item}</b>
                </div>
              ))}
              {item.class === "sell" && (
                <>
                  <Step.Group
                    vertical
                    stackable="tablet"
                    className="stepsSell"
                    attached="top"
                  >
                    <Step>
                      <Icon name="handshake" color="red" />
                      <Step.Content>
                        <Step.Title>Seznámení se</Step.Title>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Segment attached className="attachedSegment">
                    <p>
                      První schůzku vnímám jako vzájemné poznání. Rád se s Vámi
                      setkám u nás v kanceláři, kde probereme Vaše potřeby a
                      seznámím Vás jak s procesem, tak i s právními aspekty
                      prodeje nemovitosti.
                    </p>
                  </Segment>
                  <Step.Group
                    vertical
                    stackable="tablet"
                    className="stepsSell"
                    attached="top"
                  >
                    <Step>
                      <Icon name="line graph" color="red" />
                      <Step.Content>
                        <Step.Title>Analýza tržní ceny nemovitosti</Step.Title>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Segment attached className="attachedSegment">
                    <p>
                      Zhodnotím právní a stavebně-technickou stránku
                      nemovitosti. Vypracuji odborný tržní odhad vycházející z
                      cenových map a databáze prodaných nemovitostí.
                    </p>
                  </Segment>
                  <Step.Group
                    vertical
                    stackable="tablet"
                    className="stepsSell"
                    attached="top"
                  >
                    <Step>
                      <Icon name="photo" color="red" />
                      <Step.Content>
                        <Step.Title>Příprava na prodej a marketing</Step.Title>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Segment attached className="attachedSegment">
                    <p>
                      Doporučím Vám, co udělat proto, aby se Vaše nemovitost
                      stala pro zájemce atraktivnější a navrhnu vhodnou
                      marketingovou strategii zahrnující profesionální nafocení,
                      inzerci a propagaci na sociálních sítích.
                    </p>
                  </Segment>
                  <Step.Group
                    vertical
                    stackable="tablet"
                    className="stepsSell"
                    attached="top"
                  >
                    <Step>
                      <Icon name="balance scale" color="red" />
                      <Step.Content>
                        <Step.Title>Proces prodeje a právní servis</Step.Title>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Segment attached className="attachedSegment">
                    <p>
                      Zájemce o nemovitost prověřím z hlediska financování a
                      vhodnosti nemovitosti. Zajistím přípravu kompletní smluvní
                      dokumentace (kupní smlouvy a smlouvy o advokátní úschově)
                      u spolupracující advokátní kanceláře.
                    </p>
                  </Segment>
                  <Step.Group
                    vertical
                    stackable="tablet"
                    className="stepsSell"
                    attached="top"
                  >
                    <Step completed>
                      <Icon name="check" color="red" />
                      <Step.Content>
                        <Step.Title>Předání nemovitosti </Step.Title>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Segment attached className="attachedSegment">
                    <p>
                      Osobně se budu účastnit předání nemovitosti a přepisu
                      energií.
                    </p>
                  </Segment>
                </>
              )}
              {item.class === "rental" && (
                <>
                  <Step.Group
                    vertical
                    stackable="tablet"
                    className="stepsSell"
                    attached="top"
                  >
                    <Step>
                      <Icon name="handshake" color="red" />
                      <Step.Content>
                        <Step.Title>Seznámení se</Step.Title>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Segment attached className="attachedSegment">
                    <p>
                      První schůzku vnímám jako vzájemné poznání. Rád se s Vámi
                      setkám u nás v kanceláři, kde probereme Vaše potřeby a
                      seznámím Vás jak s procesem, tak právními aspekty pronájmu
                      nemovitosti.
                    </p>
                  </Segment>
                  <Step.Group
                    vertical
                    stackable="tablet"
                    className="stepsSell"
                    attached="top"
                  >
                    <Step>
                      <Icon name="line graph" color="red" />
                      <Step.Content>
                        <Step.Title>Analýza tržní ceny nájemného</Step.Title>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Segment attached className="attachedSegment">
                    <p>
                      Společně zhodnotíme silné a slabé stránky Vaší nemovitosti
                      z hlediska nájmu. Určím tržní cenu nájemného, která bude
                      vycházet z naší široké databáze pronajatých nemovitostí.
                    </p>
                  </Segment>
                  <Step.Group
                    vertical
                    stackable="tablet"
                    className="stepsSell"
                    attached="top"
                  >
                    <Step>
                      <Icon name="photo" color="red" />
                      <Step.Content>
                        <Step.Title>Příprava na prodej a marketing</Step.Title>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Segment attached className="attachedSegment">
                    <p>
                      Doporučím Vám, co udělat proto, aby se Vaše nemovitost
                      stala pro zájemce atraktivnější a navrhnu vhodnou
                      marketingovou strategii zahrnující profesionální nafocení,
                      inzerci a propagaci na sociálních sítích.
                    </p>
                  </Segment>
                  <Step.Group
                    vertical
                    stackable="tablet"
                    className="stepsSell"
                    attached="top"
                  >
                    <Step>
                      <Icon name="balance scale" color="red" />
                      <Step.Content>
                        <Step.Title>Proces pronájmu a právní servis</Step.Title>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Segment attached className="attachedSegment">
                    <p>
                      Zájemce o pronájem prověřím z hlediska vhodnosti
                      nemovitosti, stability příjmu ale i dluhů (výpis z
                      centrální evidence exekucí a insolvenčního rejstřík).
                      Zajistím přípravu kompletní smluvní dokumentace.
                    </p>
                  </Segment>
                  <Step.Group
                    vertical
                    stackable="tablet"
                    className="stepsSell"
                    attached="top"
                  >
                    <Step completed>
                      <Icon name="check" color="red" />
                      <Step.Content>
                        <Step.Title>Předání nemovitosti </Step.Title>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Segment attached className="attachedSegment">
                    <p>
                      Osobně se budu účastnit předání nemovitosti a přepisu
                      energií.
                    </p>
                  </Segment>
                </>
              )}
              <InterestButton
                label={item.label}
                areSocialNetworks={false}
                predText={item.buttonText}
              />
            </Container>
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
};

export default ServiceItem;
