import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import ResponsiveContainer from "../components/ResponsiveComponent";
import Footer from "../components/Footer";
import { Container, Segment, Header, Image, Grid } from "semantic-ui-react";
import Squares from "../components/Squares";
import ContactMe from "../components/ContactLink";
import CounterBox from "../components/CounterHomeBox";
import EstatesCatalogLink from "../components/EstatesCatalogLink";
import { IStatistics } from "../lib/library";
import { Helmet } from "react-helmet";

interface AboutMeProps {
  personalData: IStatistics;
}

const AboutMe: FunctionComponent<AboutMeProps> = (props: AboutMeProps) => {
  return (
    <div>
      <Helmet>
        <title>Ing. Jan Jírek - Osobní Informace</title>
        <meta itemProp="name" content="Ing. Jan Jírek - Osobní Informace" />
        <meta
          itemProp="description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          itemProp="image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta property="og:url" content="https://www.janjirek.cz" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ing. Jan Jírek - Osobní Informace" />
        <meta
          property="og:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          property="og:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Ing. Jan Jírek - Osobní Informace"
        />
        <meta
          name="twitter:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          name="twitter:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
      </Helmet>
      <ResponsiveContainer isHomepage={false} personalData={props.personalData}>
        <div className="content">
          <Container>
            <Segment vertical color="red" className="about">
              <Grid columns="2">
                <Grid.Row className="aboutRow">
                  <Grid.Column
                    mobile="16"
                    tablet="16"
                    computer="5"
                    widescreen="5"
                    textAlign="center"
                  >
                    <Image
                      src={`${process.env.PUBLIC_URL}/aboutPhoto.png`}
                      size="large"
                      className="aboutPhoto"
                    />
                    <p className="aboutSubtitle">
                      <i>Ing. Jan Jírek</i>
                    </p>
                    <p>
                      <b>Realitní Makléř</b>
                    </p>
                  </Grid.Column>
                  <Grid.Column
                    mobile="16"
                    tablet="16"
                    computer="11"
                    widescreen="11"
                  >
                    <Container>
                      <Container className="aboutText">
                        <Header as="h3">O mně</Header>
                        <p>
                          Jmenuji se Jan Jírek a na trhu s nemovitostmi se
                          pohybuji od roku 2012, kdy ještě jako student vysoké
                          školy jsem hledal práci, která nebude jen o penězích,
                          ale hlavně o možnosti se někam posunout.
                        </p>
                        <p>
                          Postupnou prací jsem se naučil, že kvalitně odvedená
                          realitní služba stojí na lidském přístupu, znalostech
                          a vybudování pocitu důvěry.
                        </p>
                        <p>
                          Právě Vámi projevená důvěra, je pro mne velmi
                          důležitou zpětnou vazbou, že to, co dělám, dělám
                          správně.
                        </p>
                        <p>
                          Bude mi ctí, pokud budu mít možnost Vám ukázat kvalitu
                          své práce.
                        </p>
                      </Container>
                      <Container textAlign="right">
                        <p className="sincerelyText">
                          <i>Těším se na osobní setkání.</i>
                        </p>
                        <Image
                          className="aboutSign"
                          rounded
                          src={`${process.env.PUBLIC_URL}/signature.png`}
                          size="medium"
                          verticalAlign="bottom"
                        />
                      </Container>
                    </Container>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
          <Segment className="aboutCounter">
            <Container>
              <CounterBox personalData={props.personalData} />
            </Container>
          </Segment>
          <Container>
            <Squares />
          </Container>
          <EstatesCatalogLink />
          <ContactMe
            personalData={props.personalData}
            label="Kontaktuje mě"
            className="contactAbout"
            areSocialNetworks
          />
        </div>
        <Footer personalData={props.personalData} />
      </ResponsiveContainer>
    </div>
  );
};

AboutMe.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default AboutMe;
