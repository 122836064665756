import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { Sidebar, Menu, Icon, Header } from "semantic-ui-react";
import MenuItem from "./MenuItem";
import { routes, IStatistics } from "../lib/library";
import { NavLink } from "react-router-dom";
import SocialNetwork from "./SocialNetwork";

interface MobileMenuProps {
  hideHandler(): void;
  showHandler(): void;
  sideBarOpened: boolean;
  mobile: boolean;
  personalData: IStatistics;
}

const MobileMenu: FunctionComponent<MobileMenuProps> = (
  props: MobileMenuProps
) => {
  return (
    <Sidebar
      as={Menu}
      animation="push"
      inverted
      onHide={props.hideHandler}
      onShow={props.showHandler}
      vertical
      visible={props.sideBarOpened}
    >
      <Menu.Item as={NavLink} exact to="/">
        Úvodní stránka
      </Menu.Item>
      {routes.map((item) => (
        <MenuItem key={item.path} name={item.name} path={item.path} />
      ))}
      <Menu.Item>
        <SocialNetwork inverted personalData={props.personalData} />
      </Menu.Item>
      <Menu.Item>
        <Header as="h4" inverted>
          <p>
            <Icon name="phone square" inverted size="big" />
            <a
              href={`tel:${props.personalData.phone}`}
              className="customphonemenulink"
            >
              {props.personalData.phone}
            </a>
          </p>
          <p>
            <Icon name="mail" inverted size="big" />
            <a
              href={`mailto:${props.personalData.email}`}
              className="customphonemenulink"
            >
              {props.personalData.email}
            </a>
          </p>
        </Header>
      </Menu.Item>
    </Sidebar>
  );
};

MobileMenu.propTypes = {
  hideHandler: PropTypes.func.isRequired,
  showHandler: PropTypes.func.isRequired,
  sideBarOpened: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  personalData: PropTypes.any.isRequired,
};

export default MobileMenu;
