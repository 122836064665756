import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-less/semantic.less";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "draft-js/dist/Draft.css";
import "react-image-gallery/styles/css/image-gallery.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { QueryCache, ReactQueryCacheProvider } from "react-query";

const queryCache = new QueryCache();

ReactDOM.render(
  <ReactQueryCacheProvider queryCache={queryCache}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReactQueryCacheProvider>,
  document.getElementById("root")
);
serviceWorker.unregister();
