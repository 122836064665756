import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import Mobile from "../components/MobileContainer";
import Desktop from "../components/DesktopContainer";
import { IStatistics } from "../lib/library";

interface ResponsiveContainerProps {
  children: React.ReactNode;
  isHomepage: boolean;
  personalData: IStatistics;
}

const ResponsiveContainer: FunctionComponent<ResponsiveContainerProps> = (
  props: ResponsiveContainerProps
) => {
  return (
    <div>
      <Mobile
        isHomepage={props.isHomepage}
        mobile={true}
        personalData={props.personalData}
      >
        {props.children}
      </Mobile>
      <Desktop
        isHomepage={props.isHomepage}
        mobile={false}
        personalData={props.personalData}
      >
        {props.children}
      </Desktop>
    </div>
  );
};

ResponsiveContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isHomepage: PropTypes.bool.isRequired,
};

export default ResponsiveContainer;
