import React, { FunctionComponent, useState } from "react";
import PropTypes from "prop-types";
import { getWidth, IStatistics } from "../lib/library";
import { Responsive, Sidebar, SidebarPusher } from "semantic-ui-react";
import MobileHeader from "./MobileHeader";
import MobileMenu from "./MobileMenu";

interface MobileContainerProps {
  children: React.ReactNode;
  isHomepage: boolean;
  mobile: boolean;
  personalData: IStatistics;
}

const MobileContainer: FunctionComponent<MobileContainerProps> = (
  props: MobileContainerProps
) => {
  const [sideBarOpened, setSideBarOpened] = useState<boolean>(false);

  const openSidebar = (): void => {
    setSideBarOpened(true);
  };
  const hideSidebar = (): void => {
    setSideBarOpened(false);
  };
  return (
    <Responsive
      as={Sidebar.Pushable}
      getWidth={getWidth}
      maxWidth={Responsive.onlyMobile.maxWidth}
    >
      <MobileMenu
        sideBarOpened={sideBarOpened}
        hideHandler={hideSidebar}
        showHandler={openSidebar}
        mobile={props.mobile}
        personalData={props.personalData}
      />
      <SidebarPusher dimmed={sideBarOpened}>
        <MobileHeader
          showHandler={openSidebar}
          isHomepage={props.isHomepage}
          personalData={props.personalData}
        />
        {props.children}
      </SidebarPusher>
    </Responsive>
  );
};

MobileContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isHomepage: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  personalData: PropTypes.any.isRequired,
};

export default MobileContainer;
