import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import ResponsiveContainer from "../components/ResponsiveComponent";
import Footer from "../components/Footer";
import { Container, Segment } from "semantic-ui-react";
import ServiceItem from "../components/ServiceItem";
import { IStatistics } from "../lib/library";
import { Helmet } from "react-helmet";

interface ServicesProps {
  personalData: IStatistics;
}

const Services: FunctionComponent<ServicesProps> = (props: ServicesProps) => {
  return (
    <div>
      <Helmet>
        <title>Ing. Jan Jírek - Nabídka Služeb</title>
        <meta itemProp="name" content="Ing. Jan Jírek - Nabídka Služeb" />
        <meta
          itemProp="description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          itemProp="image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta property="og:url" content="https://www.janjirek.cz" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ing. Jan Jírek - Nabídka Služeb" />
        <meta
          property="og:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          property="og:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ing. Jan Jírek - Nabídka Služeb" />
        <meta
          name="twitter:description"
          content="Ing. Jan Jírek - Osobní realitní makléř poskytující kompletní servis služeb jako je prodej, pronájem či odhad ceny nemovitosti. Realitní kancelář Pardubice a Hradec Králové. Prodej, pronájem či odhad ceny domů, bytů chalup a dalších nemovitostí."
        />
        <meta
          name="twitter:image"
          content="https://www.janjirek.cz/ContactImage.png"
        />
      </Helmet>
      <ResponsiveContainer isHomepage={false} personalData={props.personalData}>
        <div className="content">
          <Container>
            <Segment className="contactHeader">
              <ServiceItem />
            </Segment>
          </Container>
        </div>
        <Footer personalData={props.personalData} />
      </ResponsiveContainer>
    </div>
  );
};

Services.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default Services;
