import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Tab,
  List,
  Modal,
  Header,
  Container,
  Table,
  Button,
  Icon
} from "semantic-ui-react";
import {
  IMessageData,
  sortMessages,
  dateFormatterWithTime
} from "../../lib/library";
import { getMessages, deleteMessage, deleteAllMessages } from "../../lib/axios";
import ConfirmationModal from "./ConfirmationModal";

interface IAdminMessageData extends IMessageData {
  IsModalOpen: boolean;
  IsOpenConfirm: boolean;
}

const Messages: FunctionComponent<{}> = () => {
  const [content, setContent] = useState<IAdminMessageData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isopenedConfirm, setIsOpenedConfirm] = useState<boolean>(false);

  const handleCloseMainModal = (index: number): void => {
    const state = [...content];
    const stateItem = state[index];
    stateItem.IsModalOpen = false;
    setContent(state);
  };

  const handleCloseConfirmSubModal = (index: number): void => {
    const state = [...content];
    const stateItem = state[index];
    stateItem.IsOpenConfirm = false;
    setContent(state);
  };

  const handleOpenConfirmSubModal = (index: number): void => {
    const state = [...content];
    const stateItem = state[index];
    stateItem.IsOpenConfirm = true;
    setContent(state);
  };

  const handleCloseConfirmModal = (): void => {
    setIsOpenedConfirm(false);
  };

  const handleOpenMainModal = (index: number): void => {
    const state = [...content];
    const stateItem = state[index];
    stateItem.IsModalOpen = true;
    setContent(state);
  };

  const handleOpenConfirmModal = (): void => {
    setIsOpenedConfirm(true);
  };

  const getData = async () => {
    const result = await getMessages();
    if (result instanceof Array) {
      let sortedResult = [...result].sort(sortMessages);
      sortedResult.forEach((element: IAdminMessageData) => {
        element.IsModalOpen = false;
        element.IsOpenConfirm = false;
      });
      setContent(sortedResult);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Tab.Pane loading={loading}>
      <List selection relaxed divided>
        {content.map((item: IAdminMessageData, index: number) => (
          <Modal
            open={item.IsModalOpen}
            key={item._id}
            trigger={
              <List.Item
                key={item._id}
                onClick={() => {
                  handleOpenMainModal(index);
                }}
              >
                <List.Icon name="folder" />
                <List.Header>
                  {item.name} {item.surname}
                </List.Header>
                <List.Description>
                  {item.createdDate !== undefined
                    ? dateFormatterWithTime(new Date(item.createdDate))
                    : ""}
                </List.Description>
              </List.Item>
            }
            closeIcon={
              <Icon
                name="close"
                onClick={() => {
                  handleCloseMainModal(index);
                }}
              />
            }
            dimmer="blurring"
          >
            <Modal.Header>
              <Header icon="archive" content={`${item.name} ${item.surname}`} />
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Container text>
                  <Table
                    celled
                    inverted
                    verticalAlign="middle"
                    padded
                    className="messages"
                    selectable
                  >
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <Header.Subheader>Emailová adresa:</Header.Subheader>
                        </Table.Cell>
                        <Table.Cell>{item.email}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Header.Subheader>Telefon:</Header.Subheader>
                        </Table.Cell>
                        <Table.Cell>{item.phoneNumber}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  {item.message}
                </Container>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <ConfirmationModal
                isOpened={item.IsOpenConfirm}
                index={index}
                handleCloseParent={handleCloseMainModal}
                header="Smazat Zprávu"
                message="Opravdu chcete smazat tuto zprávu? Operace je nevratná!"
                databaseAction={deleteMessage}
                handleCloseModal={() => handleCloseConfirmSubModal(index)}
                id={item._id}
                updateFunc={getData}
              >
                <Button
                  negative
                  icon="trash alternate"
                  labelPosition="left"
                  content="Smazat Zprávu"
                  onClick={() => handleOpenConfirmSubModal(index)}
                />
              </ConfirmationModal>
            </Modal.Actions>
          </Modal>
        ))}
      </List>
      <ConfirmationModal
        isOpened={isopenedConfirm}
        header="Smazat Všechny Zprávy"
        message="Opravdu chcete smazat všechny zprávy? Operace je nevratná!"
        databaseAction={deleteAllMessages}
        handleCloseModal={handleCloseConfirmModal}
        updateFunc={getData}
      >
        <Button
          negative
          icon="trash alternate"
          labelPosition="left"
          content="Smazat Všechny Zprávy"
          onClick={handleOpenConfirmModal}
        />
      </ConfirmationModal>
    </Tab.Pane>
  );
};

export default Messages;
