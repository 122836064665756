import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { Container, Grid, Statistic } from "semantic-ui-react";
import { IStatistics } from "../lib/library";
import CountUp from "react-countup";

interface CounterHomeBoxProps {
  personalData: IStatistics;
}

const CounterHomeBox: FunctionComponent<CounterHomeBoxProps> = (
  props: CounterHomeBoxProps
) => {
  return (
    <Container className="homeBoxCounter">
      <Grid stackable columns="3">
        <Grid.Row textAlign="center" columns="3">
          <Grid.Column widescreen="5" computer="5" tablet="5" mobile="5">
            <Statistic>
              <Statistic.Value>
                <CountUp end={props.personalData.experience} duration={3} />
              </Statistic.Value>
              <Statistic.Label>let zkušeností</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column widescreen="6" computer="6" tablet="6" mobile="6">
            <Statistic>
              <Statistic.Value>
                <CountUp end={props.personalData.realEstates} duration={3} />
                <b className="plus">+</b>
              </Statistic.Value>
              <Statistic.Label>zobchodovaných nemovitostí</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column widescreen="4" computer="4" tablet="4" mobile="4">
            <Statistic>
              <Statistic.Value>
                <CountUp end={props.personalData.clients} duration={3} />
                <b className="plus">+</b>
              </Statistic.Value>
              <Statistic.Label>spokojených klientů</Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

CounterHomeBox.propTypes = {
  personalData: PropTypes.any.isRequired,
};

export default CounterHomeBox;
