import React, { FunctionComponent, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Segment,
  Menu,
  Icon,
  Grid,
  Visibility,
} from "semantic-ui-react";
import PageLogo from "./PageLogo";
import { IStatistics } from "../lib/library";

interface headerProps {
  isHomepage: boolean;
  showHandler(): void;
  personalData: IStatistics;
}

const MobileHeader: FunctionComponent<headerProps> = (props: headerProps) => {
  const [fixed, setFixed] = useState<boolean>(false);

  const showFixedMenu = (): void => setFixed(true);
  const hideFixedMenu = (): void => setFixed(false);

  const setClassName = (): string => {
    if (props.isHomepage) {
      return "homepageMobile";
    } else {
      return "otherMobile";
    }
  };

  return (
    <Visibility
      once={false}
      onBottomPassed={showFixedMenu}
      onBottomPassedReverse={hideFixedMenu}
    >
      <Segment className={setClassName()} inverted textAlign="center" vertical>
        <Container>
          <Grid>
            <Grid.Row columns="2">
              <Grid.Column mobile="4">
                <Menu
                  inverted={!fixed}
                  pointing={!fixed}
                  secondary={!fixed}
                  size="large"
                  fixed={fixed ? "top" : undefined}
                >
                  <Menu.Item onClick={props.showHandler}>
                    <Icon name="sidebar" />
                  </Menu.Item>
                </Menu>
              </Grid.Column>
              <Grid.Column mobile="8">
                <PageLogo personalData={props.personalData} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </Visibility>
  );
};

MobileHeader.propTypes = {
  isHomepage: PropTypes.bool.isRequired,
  showHandler: PropTypes.func.isRequired,
  personalData: PropTypes.any.isRequired,
};

export default MobileHeader;
