import { getReferences } from "../lib/axios";
import { useQuery } from "react-query";

interface QueryResultSet {
  data: any;
  isLoading: boolean;
  isFetching: boolean;
}

const useReferences = (): QueryResultSet => {
  const { data, isLoading, isFetching } = useQuery(
    "references",
    getReferences,
    { staleTime: 1800000 }
  );
  return { data, isLoading, isFetching };
};

export default useReferences;
